import React, { useState } from 'react';

import { throttle } from '@vkontakte/vkjs';
import { useSelector } from 'react-redux';

import { getTemperamentOptions } from './constants';
import { ResultContent } from './ResultContent';
import { ResultContentContainerProps } from './types';
import eventsApi from '../../../../../../api/EventsApi';
import predictionsApi from '../../../../../../api/PredictionApi';
import { EVENT_NAME, EVENT_TYPE } from '../../../../../../constants/Event';
import { POPOUT } from '../../../../../../constants/Popout';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { usePopout, useSetPopoutAlert } from '../../../../../../hooks/usePopout';
import { setPopout as setCommonPopout } from '../../../../../../store/popoutSlice';
import {
  selectPredictionParams,
  selectPredictionVkId
} from '../../../../../../store/predictionSlice/predictionSelectors';
import { selectLocation } from '../../../../../../store/routerSlice/routerSelectors';
import { selectIsDesktop } from '../../../../../../store/settingsSlice/settingsSelectors';
import { selectUserVKID } from '../../../../../../store/userSlice/userSelectors';

const ResultContentContainer: React.FC<ResultContentContainerProps> = ({
  resultType,
  isOtherUserBeenPredicted,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const isDesktop = useSelector(selectIsDesktop);
  const location = useSelector(selectLocation);
  const userId = useSelector(selectUserVKID);
  const predictionVkId = useSelector(selectPredictionVkId);
  const params = useSelector(selectPredictionParams);
  const [hasUserAgreed, setHasUserAgreed] = useState<boolean | undefined>(undefined);
  const { setPopout, resetPopout } = usePopout();
  const setPopoutAlert = useSetPopoutAlert();
  const temperamentData = getTemperamentOptions(isDesktop ? { margin: '12px 0px' } : {})[resultType];

  const onAppWithTestsClick = throttle(() => {
    setTimeout(() => window.open('https://vk.com/ticspsytests', isDesktop ? '_blank' : '_top'));

    eventsApi.sendStatistics({
      type: EVENT_TYPE.FOLLOW,
      name: EVENT_NAME.OPEN_APP_WITH_TESTS,
      route: location,
    });
  }, 2500);

  const onRadioChange = async (newValue: boolean) => {
    const oldValue = hasUserAgreed;

    try {
      if (!userId || !predictionVkId) {
        setPopoutAlert(
          'Ошибка при обработке обратной связи',
          'Пожалуйста, попробуйте еще раз.',
        );
        setHasUserAgreed(oldValue);
        return;
      }

      setPopout(POPOUT.SMART_SPINNER);
      await predictionsApi.updateHasUserAgreed(
        predictionVkId,
        params,
        newValue,
      );
      resetPopout();

      setHasUserAgreed(newValue);

      dispatch(setCommonPopout({
        type: POPOUT.SUCCESS_SNACKBAR,
        meta_info: {
          duration: 2000,
          message: 'Ваш отзыв учтен. Благодарим за обратную связь!',
        },
      }));
    } catch {
      setPopoutAlert(
        'Ошибка при обработке обратной связи',
        'Пожалуйста, попробуйте еще раз.',
      );
      setHasUserAgreed(oldValue);
    }
  };

  const shareResult = async () => {
    dispatch(setCommonPopout({
      type: POPOUT.SHARING_POPOUT,
      meta_info: {
        temperamentType: resultType,
        isOtherUserBeenPredicted
      }
    }));
  };

  return (
    <ResultContent
      resultType={resultType}
      isDesktop={isDesktop}
      shareResult={shareResult}
      onAppWithTestsClick={onAppWithTestsClick}
      temperamentData={temperamentData}
      onRadioChange={onRadioChange}
      hasUserAgreed={hasUserAgreed}
      isOtherUserBeenPredicted={isOtherUserBeenPredicted}
      {...props}
    />
  );
};

export default ResultContentContainer;

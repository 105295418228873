import React from 'react';

import { DesktopPredictionPanel } from './DesktopPredictionPanel';
import { MobilePredictionPanel } from './MobilePredictionPanel';
import { PredictionPanelProps } from './types';


export const PredictionPanel: React.FC<PredictionPanelProps> = ({ id, isDesktop }) => {
  return isDesktop ? (
    <DesktopPredictionPanel id={id} />
  ) : (
    <MobilePredictionPanel id={id} />
  );
};

import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from './useAppDispatch';
import eventsApi from '../api/EventsApi';
import usersApi from '../api/UsersApi';
import { getUsersCommonData } from '../api/VKWebApi';
import { EVENT_NAME, EVENT_TYPE } from '../constants/Event';
import { setSelectedUserData, setIsSelectedUserPurchased } from '../store/FriendSearchSlice';
import { selectFriends } from '../store/FriendSearchSlice/popoutSelectors';
import { SelectedUserDataType } from '../store/FriendSearchSlice/types';
import { selectLocation } from '../store/routerSlice/routerSelectors';
import { selectUserAccessToken } from '../store/userSlice/userSelectors';
import { handleErrorToStore } from '../utils/handleErrorToStore';


export const useSetSelectedFriend = () => {
  const dispatch = useAppDispatch();
  const friends = useSelector(selectFriends);
  const accessToken = useSelector(selectUserAccessToken);
  const location = useSelector(selectLocation);

  return useCallback(async (vkId: number, isPurchased: boolean = false) => {
    let hasAlreadyBeenPurchased = isPurchased;
    let newSelectedUserData: SelectedUserDataType | undefined = undefined;
    if (!hasAlreadyBeenPurchased) {
      const response = await usersApi.getHasAlreadyBeenPurchased(vkId);
      if (
        response &&
          'has_friend_already_been_purchased' in response &&
          typeof response.has_friend_already_been_purchased === 'boolean') {
        hasAlreadyBeenPurchased = response.has_friend_already_been_purchased;
      }
    }

    if (friends) {
      for (const friendData of friends) {
        if (friendData.vkId === vkId) {
          newSelectedUserData = friendData;
        }
      }
    }

    if (!Boolean(newSelectedUserData) && accessToken) {
      try {
        const response = await getUsersCommonData([vkId], accessToken);
        if (response.length > 0) {
          newSelectedUserData = response[0];
        }
      } catch (error) {
        await eventsApi.sendStatistics({
          type: EVENT_TYPE.VK_API,
          name: EVENT_NAME.USERS_GET_VK_API_ERROR,
          data: {
            message: 'Ошибка при получении общих данных пользователя (useSetSelectedFriend)',
            error: handleErrorToStore(error),
          },
          route: location,
        });
        throw error;
      }
    }

    dispatch(setSelectedUserData(newSelectedUserData));
    dispatch(setIsSelectedUserPurchased(hasAlreadyBeenPurchased));
  }, [accessToken, dispatch, friends, location]);
};

import React from 'react';

import { useSelector } from 'react-redux';

import { DesktopPredictionPanel } from './DesktopPredictionPanel';
import { DesktopPredictionPanelContainerProps } from './types';
import eventsApi from '../../../../../api/EventsApi';
import { EVENT_NAME, EVENT_TYPE } from '../../../../../constants/Event';
import { PANEL } from '../../../../../constants/Panel';
import { POPOUT } from '../../../../../constants/Popout';
import { usePopout, useSetPopoutAlert } from '../../../../../hooks/usePopout';
import { useRouter } from '../../../../../hooks/useRouter';
import { selectLocation } from '../../../../../store/routerSlice/routerSelectors';
import { selectUserGrantedTokenScope } from '../../../../../store/userSlice/userSelectors';
import { useOnGetTemperamentClick } from '../hooks';


const DesktopPredictionPanelContainer: React.FC<DesktopPredictionPanelContainerProps> = (props) => {
  const router = useRouter();
  const grantedTokenScope = useSelector(selectUserGrantedTokenScope);
  const setPopoutAlert = useSetPopoutAlert();
  const goToAboutUs = () => router.forward({ panel: PANEL.ABOUT_US });
  const {
    onGetMyTemperamentClick,
    onGetFriendTemperamentClick
  } = useOnGetTemperamentClick();
  const { setPopout, resetPopout } = usePopout();
  const location = useSelector(selectLocation);

  const handleGetMyTemperamentClick = async () => {
    setPopout(POPOUT.SMART_SPINNER);
    await eventsApi.sendStatistics({
      type: EVENT_TYPE.GO_THROUGH,
      name: EVENT_NAME.USER_CLICKED_CHECK_ME_BUTTON,
      data: { message: 'Clicked on desktop' },
      route: location,
    });
    resetPopout();

    onGetMyTemperamentClick();
    router.forward({ panel: PANEL.RESULT });
  };

  const onGetToken = async () => {
    setPopout(POPOUT.SMART_SPINNER);
    const accessToken = await onGetFriendTemperamentClick();
    resetPopout();
    if (accessToken) {
      router.forward({ panel: PANEL.USER_ENTERING });
    }
  };

  const handleGetFriendTemperamentClick = async () => {
    setPopout(POPOUT.SMART_SPINNER);
    await eventsApi.sendStatistics({
      type: EVENT_TYPE.GO_THROUGH,
      name: EVENT_NAME.USER_CLICKED_CHECK_SOMEONE_BUTTON,
      data: { message: 'Clicked on desktop' },
      route: location,
    });
    resetPopout();

    // TODO: Временное
    // setPopoutAlert(
    //   'Функциональность недоступна',
    //   'На текущий момент приложение находится в полу-тестовом режиме и проходит модерацию со стороны ВК. Данная функциональность содержит оплату голосами, а она недоступна без прохождения модерации.',
    //   () => {
    //   },
    //   'Хорошо'
    // );

    if (grantedTokenScope && grantedTokenScope.includes('friends')) {
      onGetToken();
    } else {
      setPopoutAlert(
        'Необходимы дополнительные разрешения',
        'Для дальнейшей работы приложения нужен доступ к Вашим друзьям. Без этого доступа узнать темперамент не получится',
        onGetToken,
        'Хорошо'
      );
    }
  };

  return (
    <DesktopPredictionPanel
      goToAboutUs={goToAboutUs}
      handleGetMyTemperamentClick={handleGetMyTemperamentClick}
      handleGetFriendTemperamentClick={handleGetFriendTemperamentClick}
      {...props}
    />
  );
};

export default DesktopPredictionPanelContainer;

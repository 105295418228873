import React, { forwardRef } from 'react';

import { Icon20ShoppingCartOutline } from '@vkontakte/icons';
import { Spacing } from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import { PaymentLayoutPropsType } from './types';
import { IconedText } from '../../../../../../components/IconedText';
import { FRIEND_PREDICTION_PRICE } from '../../../../../../constants/Common';
import { CholericSVG } from '../../../../../../images/mobile/CholericSVG';
import { MelancholicSVG } from '../../../../../../images/mobile/MelancholicSVG';
import { PersonSVG } from '../../../../../../images/mobile/PersonSVG';
import { PhlegmaticSVG } from '../../../../../../images/mobile/PhlegmaticSVG';
import { SanguineSVG } from '../../../../../../images/mobile/SanguineSVG';

export const PaymentLayout = forwardRef<HTMLDivElement, PaymentLayoutPropsType>(({
  children,
  delayAnimation = 0,
  needBuyingDescription = true,
  style
}, ref) => {
  return (
    <div style={{ ...style, paddingTop: '16px' }} ref={ref} >
      <PersonSVG width={0} height={0} />
      <MelancholicSVG width={0} height={0}/>
      <CholericSVG width={0} height={0}/>
      <PhlegmaticSVG width={0} height={0}/>
      <SanguineSVG width={0} height={0}/>

      <div style={{ margin: '0 82px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <AnimatePresence>
          <motion.div
            animate={{ scale: [0.9, 1.06, 1] }}
            transition={{
              duration: 0.6,
              delay: delayAnimation
            }}
          >
            <PersonSVG width="100%" height="auto" />
            {/*<MelancholicSVG width="100%" height="auto" />*/}
          </motion.div>
        </AnimatePresence>
      </div>

      {children}
      {needBuyingDescription && (
        <div style={{ margin: '0 16px' }}>
          <IconedText
            icon={
              <AnimatePresence>
                <motion.div
                  animate={{ x: [10, -8, 0] }}
                  transition={{
                    duration: 0.6,
                    delay: delayAnimation
                  }}
                >
                  <Icon20ShoppingCartOutline color="#99A2AD"/>
                </motion.div>
              </AnimatePresence>
            }
            text={`Проверка другого человека становится доступна после оплаты ${FRIEND_PREDICTION_PRICE} голоса. В случае ошибки оплата голосов не будет произведена`}
          />
        </div>
      )}

      <Spacing size={24} />

    </div>
  );
});

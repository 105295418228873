import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import friendSearchReducer from './FriendSearchSlice';
import popoutReducer from './popoutSlice';
import predictionReducer from './predictionSlice';
import routerReducer from './routerSlice';
import settingsReducer from './settingsSlice';
import userReducer from './userSlice';


const rootReducer = combineReducers({
  friendSearch: friendSearchReducer,
  prediction: predictionReducer,
  user: userReducer,
  settings: settingsReducer,
  router: routerReducer,
  popout: popoutReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch;

export default store;

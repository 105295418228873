import { prepareError } from '../utils/prepareError';

async function api(endpoint: string, body?: object, customConfig: RequestInit = {}) {
  const token = window.location.search || '';
  
  const headers: HeadersInit = {
    'content-type': 'application/json',
    Authorization: token,
  };
  
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(`api/${endpoint}`, config);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Something went wrong...');
    }
    return data;
  } 
  catch (error) {
    throw prepareError(error);
  }
}

export default api;

import React from 'react';

import { connect } from 'react-redux';

import { MainViews } from './MainViews';
import { MainContainerProps } from './types';
import { useRouter } from '../hooks/useRouter';
import { RootState } from '../store';
import { selectView, selectPanel, selectModal } from '../store/routerSlice/routerSelectors';
import { selectIsDesktop, selectHasHeader } from '../store/settingsSlice/settingsSelectors';


const MainViewsContainer: React.FC<MainContainerProps> = (props) => {
  const router = useRouter();

  return (
    <MainViews
      {...props}
      onModalClose={router.back}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
    hasHeader: selectHasHeader(state),
    activeView: selectView(state),
    activePanel: selectPanel(state),
    activeModal: selectModal(state),
  };
}

export default connect(mapStateToProps)(MainViewsContainer);

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';


export const selectPrediction = (state: RootState) => state.prediction;

export const selectPredictionVkId = createSelector(
  selectPrediction,
  (prediction) => prediction.vkId,
);

export const selectPredictionParams = createSelector(
  selectPrediction,
  (prediction) => prediction.predictionParams,
);

import { ErrorSnackbar } from './ErrorSnackbar';
import { PopoutAlert } from './PopoutAlert';
import { SharingPopout } from './SharingPopout';
import { SmartSpinner } from './SmartSpinner';
import { SuccessSnackbar } from './SuccessSnackbar';
import { POPOUT } from '../../constants/Popout';


const popoutMap = {
  [POPOUT.SMART_SPINNER]: SmartSpinner,
  [POPOUT.ERROR_SNACKBAR]: ErrorSnackbar,
  [POPOUT.POPOUT_ALERT]: PopoutAlert,
  [POPOUT.SHARING_POPOUT]: SharingPopout,
  [POPOUT.SUCCESS_SNACKBAR]: SuccessSnackbar,
};

export default popoutMap;

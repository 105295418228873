import React from 'react';

import {
  Button,
  Group,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Spacing,
  Text,
  Title,
} from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './DesktopAboutUs.module.css';
import { DesktopAboutUsProps } from './types';
import { ABOUT_DATA } from '../../../../constants/AboutData';
import { IntroCoverSVG } from '../../../../images/desktop/IntroCoverSVG';

export const DesktopAboutUs: React.FC<DesktopAboutUsProps> = ({
  id,
  onBackClick,
  goToPrediction
}) => {
  return (
    <Panel id={id} className={styles.panel}>
      <PanelHeader before={<PanelHeaderBack onClick={onBackClick}/>}>
        О проекте
      </PanelHeader>
      <Group className={styles.container} separator="hide">
        <AnimatePresence>
          <motion.div animate={{ scale: [1, 1.02, 1] }} transition={{ duration: 0.3 }}>
            <IntroCoverSVG width="100%" height="auto" />
          </motion.div>
        </AnimatePresence>
        <Spacing size={20} />
        <Title level="2" className={styles.title}>
          Хотите узнать свой тип темперамента?
        </Title>
        <Text className={styles.text}>
          {
            ABOUT_DATA.map(text => (
              <p className={styles.paragraph}>
                {text}
              </p>
            ))
          }
        </Text>
        <Spacing size={14} />
        <Button
          onClick={goToPrediction}
          mode={'primary'}
          stretched
          size="l"
        >
          Узнать тип темперамента
        </Button>
      </Group>
    </Panel>
  );
};

import React from 'react';

import { Icon24CancelOutline, Icon28ErrorCircleOutline } from '@vkontakte/icons';
import { Snackbar } from '@vkontakte/vkui';

import styles from './ErrorSnackbar.module.css';
import { ErrorSnackbarProps } from './types';

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  message,
  duration,
  onSnackbarClose
}) => {
  return (
    <Snackbar
      onClose={onSnackbarClose}
      duration={duration}
      before={<Icon28ErrorCircleOutline fill='var(--vkui--color_icon_negative)' />}
      after={(
        <Icon24CancelOutline
          width={22}
          height={22}
          className={styles.cancelIcon} 
          onClick={onSnackbarClose}
        />
      )}
    >
      {message}
    </Snackbar>
  );
};

import React, { useEffect } from 'react';

import bridge from '@vkontakte/vk-bridge';
import {
  usePlatform,
  Platform,
  useAdaptivityWithJSMediaQueries,
  ConfigProvider,
  AdaptivityProvider,
} from '@vkontakte/vkui';
import { useSelector } from 'react-redux';

import eventsApi from './api/EventsApi';
import usersApi from './api/UsersApi';
import { INTRO_IMAGE_BASE_64 } from './constants';
import { INTRO_SLIDES_DATA } from './constants/AboutData';
import { EVENT_NAME, EVENT_TYPE } from './constants/Event';
import { useAppDispatch } from './hooks/useAppDispatch';
import { selectLocation } from './store/routerSlice/routerSelectors';
import { updateSettings } from './store/settingsSlice';
import { selectAppearance } from './store/settingsSlice/settingsSelectors';
import { fetchUser, updateUser } from './store/userSlice';
import MainViews from './views';

export const App: React.FC = () => {
  const platform = usePlatform();
  const { isDesktop } = useAdaptivityWithJSMediaQueries();
  const location = useSelector(selectLocation);

  const dispatch = useAppDispatch();
  const appearance = useSelector(selectAppearance);

  useEffect(() => {
    dispatch(fetchUser())
      .then((action) => action.payload)
      .then(async (userData) => {
        if (!userData || typeof userData !== 'object') {
          return;
        }

        const hasAreIntroductoryViewsShownProperty = userData.hasOwnProperty('are_introductory_views_shown');
        const areIntroductoryViewsShown = userData.are_introductory_views_shown;

        if (!hasAreIntroductoryViewsShownProperty || !areIntroductoryViewsShown) {
          bridge.send('VKWebAppShowSlidesSheet', {
            slides: INTRO_SLIDES_DATA.map(({ title, description }) => ({
              media: {
                blob: INTRO_IMAGE_BASE_64,
                type: 'image'
              },
              title,
              subtitle: description
            }))
          })
            .then(async (data) => {
              if (data.result) {
                await usersApi.updateAfterShowingIntroductoryViews();
              }
            })
            .catch((error) => {
              // Ошибка
              console.log('error', error);
            });
        }
      });

    const initialAppSettings = {
      platform,
      isDesktop,
      hasHeader: platform !== Platform.VKCOM, // TODO: Нужно?
    };

    dispatch(updateSettings(initialAppSettings));

    bridge.subscribe(({ detail: { type, data } }) => {
      if (type === 'VKWebAppUpdateConfig') {
        // Удаляем id приложения, который приходит из этого метода,
        // так как на мобильных устройствах он не совпадает с реальным id,
        // что приводит к ошибке при обращении к другим методам
        // @ts-ignore
        delete data.app_id;
        dispatch(updateSettings(data));
      }
    });

    if (platform === Platform.IOS) {
      bridge.send('VKWebAppSetSwipeSettings', { history: true });
    }

    // TODO: что из этого нужно?
    bridge.send('VKWebAppGetUserInfo')
      .then((data) => dispatch(updateUser(data)));

    bridge.send('VKWebAppGetLaunchParams')
      .then(({ vk_access_token_settings: scope }) => dispatch(updateUser({
        granted_token_scope: Boolean(scope) ? scope.split(',') : []
      })));

  }, [dispatch, platform, isDesktop, location]);

  useEffect(() => {
    eventsApi.sendStatistics({
      type: EVENT_TYPE.DEVICE_TYPE,
      name: EVENT_NAME.USER_USED_DEVICE,
      route: location,
      data: { isDesktop, platform }
    });
  }, []);

  return (
    <ConfigProvider appearance={appearance}>
      <AdaptivityProvider>
        <MainViews/>
      </AdaptivityProvider>
    </ConfigProvider>
  );
};

export default App;

import React from 'react';

import { Caption, classNames, Text } from '@vkontakte/vkui';
import ProgressiveImage from 'react-progressive-graceful-image';

import styles from './FamousPerson.module.css';
import { FamousPersonProps } from './types';

export const FamousPerson: React.FC<FamousPersonProps> = ({ image, name, subtitle, smallImage }) => {
  return (
    <>
      <ProgressiveImage
        src={image}
        placeholder={smallImage}
      >
        {(src, loading) => (
          <img
            src={src}
            alt={name}
            className={classNames(
              styles.image,
              loading && styles.blurred
            )}
          />
        )}
      </ProgressiveImage>
      <div className={styles.textContainer}>
        <Text className={styles.title}>
          {name}
        </Text>
        <Caption className={styles.caption}>
          {subtitle}
        </Caption>
      </div>
    </>
  );
};

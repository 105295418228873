export const EVENT_TYPE = {
  REPOST: 'repost',
  FOLLOW: 'follow',
  STORY: 'story',
  PREDICTION: 'prediction',
  PAYMENT: 'payment',
  DEVICE_TYPE: 'device_type',
  VK_API: 'vk_api',
  GO_THROUGH: 'go_through',
} as const;

export const EVENT_NAME = {
  OPEN_APP_WITH_TESTS: 'app_with_tests_opened_by_link',
  RESULT_REPOST_MY_SUCCESS: 'successfully_shared_my_result_on_wall',
  RESULT_REPOST_OTHER_SUCCESS: 'successfully_shared_other_result_on_wall',
  RESULT_REPOST_MY_FAILURE: 'failed_to_share_my_result_on_wall',
  RESULT_REPOST_OTHER_FAILURE: 'failed_to_share_other_result_on_wall',
  ERROR_DURING_PREDICTION: 'error_during_getting_prediction_result_occurred',
  USER_VK_ID_NOT_FOUND_DURING_PAYMENT: 'user_vk_id_not_found_during_payment',
  DID_NOT_RECEIVE_RESPONSE_FROM_SERVER: 'did_not_receive_response_from_server',
  TOO_LONG_PREDICTION: 'too_long_prediction',
  PREDICTION_STATISTICS: 'prediction_statistics',
  USERS_GET_VK_API_ERROR: 'users_get_vk_api_error',
  FRIENDS_GET_VK_API_ERROR: 'friends_get_vk_api_error',
  USER_USED_DEVICE: 'user_opened_app',
  ACCESS_TOKEN_ERROR: 'access_token_error',
  USER_CLICKED_CHECK_ME_BUTTON: 'user_clicked_check_me_button',
  USER_CLICKED_CHECK_SOMEONE_BUTTON: 'user_clicked_check_someone_button',
  USER_GAVE_ACCESS_TO_FRIENDS: 'user_gave_access_to_friends',
} as const;

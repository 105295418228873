import React from 'react';

import { Icon20ShoppingCartOutline, Icon28SearchStarsOutline } from '@vkontakte/icons';
import {
  Button,
  FormItem,
  FormLayout,
  Group,
  Input,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Spacing,
  Text,
} from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './DesktopUserEntering.module.css';
import { DesktopUserEnteringProps } from './types';
import { IconedText } from '../../../../components/IconedText';
import { FRIEND_PREDICTION_PRICE } from '../../../../constants/Common';
import { PersonSVG } from '../../../../images/desktop/PersonSVG';

export const DesktopUserEntering: React.FC<DesktopUserEnteringProps> = ({
  id,
  onBackClick,
  handlePayButtonClick,
  handleUserSearchButton,
  onTextInputChange,
  textInput,
  error
}) => {
  return (
    <Panel id={id} className={styles.panel}>
      <PanelHeader before={<PanelHeaderBack onClick={onBackClick}/>}>
        AI темперамент
      </PanelHeader>
      <Group className={styles.container} separator="hide">
        <AnimatePresence>
          <motion.div
            animate={{ scale: [1, 1.02, 1] }}
            transition={{ duration: 0.3 }}
          >
            <PersonSVG width="100%" height="auto" />
          </motion.div>
        </AnimatePresence>
        <Spacing size={40} />
        <div className={styles.informativeContent}>
          <div className={styles.linkContainer}>
            <FormLayout className={styles.formContainer}>
              <FormItem
                top="Ссылка на страницу пользователя"
                status={ error ? 'error' : 'default' }
                bottom={ error }
              >
                <Input
                  value={textInput}
                  type="text"
                  placeholder="Введите ссылку"
                  onChange={onTextInputChange}
                />
              </FormItem>
            </FormLayout>
          </div>
          <div className={styles.toFriendButtonOuterContainer}>
            <div className={styles.toFriendButtonInnerContainer}>
              <Button
                mode="tertiary"
                size="m"
                before={<Icon28SearchStarsOutline />}
                stretched
                onClick={handleUserSearchButton}
              >
                Темперамент друга
              </Button>
            </div>
          </div>
        </div>
        <Spacing size={32} />
        <Text className={styles.invitationText}>
          Оплатите голосами и узнайте, что страницы людей говорят об их темпераменте
        </Text>
        <Spacing size={12} />
        <div className={styles.bottom}>
          <Button
            onClick={handlePayButtonClick}
            mode="primary"
            size="l"
          >
            Оплатить и узнать результаты
          </Button>
          <Spacing size={32} />
          <div className={styles.iconedTextContainer}>
            <IconedText
              icon={
                <AnimatePresence>
                  <motion.div animate={{ x: [6, -4, 0] }} transition={{ duration: 0.3 }}>
                    <Icon20ShoppingCartOutline className={styles.icon}/>
                  </motion.div>
                </AnimatePresence>
              }
              text={(
                <span className={styles.iconedText}>
                  Проверка другого человека становится доступна после оплаты {FRIEND_PREDICTION_PRICE} голоса. В случае ошибки оплата голосов не будет произведена
                </span>
              )}
            />
          </div>
        </div>
      </Group>
    </Panel>
  );
};

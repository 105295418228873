import { useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from './useAppDispatch';
import { CONTENT_ITEMS } from '../constants/Content';
import { defaultLocation, LocationType } from '../constants/History';
import { PANEL } from '../constants/Panel';
import { STRUCTURE } from '../constants/Structure';
import { updateLocation } from '../store/routerSlice';
import { selectLocation } from '../store/routerSlice/routerSelectors';
import { HandlerType } from '../store/routerSlice/types';
import { selectIsDesktop } from '../store/settingsSlice/settingsSelectors';
import { compareStringifiedObjects } from '../utils/compareStringifiedObjects';
import { isUndefined } from '../utils/isUndefined';

export type UseRouterType = {
  forward: (recievedLocation: Partial<LocationType> | { location: Partial<LocationType>, handler: HandlerType }, isSilent?: boolean) => Promise<void>;
  back: () => void;
  replace: (recievedLocation: Partial<LocationType>, isSilent?: boolean) => Promise<void>;
};

const getUpdatedLocation = (location: any, isDesktop: boolean) => {
  const updatedLocation = JSON.parse(JSON.stringify(location));

  if (isDesktop) {
    if (location && isUndefined(location.panel)) {
      switch (location.content) {
        case CONTENT_ITEMS.PREDICTION:
          updatedLocation.panel = PANEL.PREDICTION;
          break;
        case CONTENT_ITEMS.ABOUT_US:
          updatedLocation.panel = PANEL.ABOUT_US;
          break;
        case CONTENT_ITEMS.USER_ENTERING:
          updatedLocation.panel = PANEL.USER_ENTERING;
          break;
        case CONTENT_ITEMS.AFTER_USER_CHOOSE:
          updatedLocation.panel = PANEL.AFTER_USER_CHOOSE;
          break;
      }
    }
  } else if ([
    CONTENT_ITEMS.PREDICTION,
    CONTENT_ITEMS.ABOUT_US,
    CONTENT_ITEMS.USER_ENTERING,
    CONTENT_ITEMS.AFTER_USER_CHOOSE
  ].includes(location.content)) {
    updatedLocation.panel = PANEL.PREDICTION;
  }
  return updatedLocation;
};

export const useRouter = (): UseRouterType => {
  const location = useSelector(selectLocation);
  const dispatch = useAppDispatch();
  const isDesktop = useSelector(selectIsDesktop);

  const onPopState = useCallback((event: PopStateEvent) => {
    const updatedLocation: LocationType = event.state || defaultLocation;
    dispatch(updateLocation({ location: getUpdatedLocation(updatedLocation, isDesktop), isDesktop }));
  }, [dispatch, isDesktop]);

  const forward = useCallback(async (
    recievedLocationData: Partial<LocationType> | { location: Partial<LocationType>, handler: HandlerType },
    isSilent: boolean = false
  ) => {
    let recievedLocation = recievedLocationData;
    let handler = 'none';
    if (recievedLocationData.hasOwnProperty('location')) {
      // @ts-ignore
      recievedLocation = recievedLocationData.location;
      // @ts-ignore
      handler = recievedLocationData.handler;
    }

    recievedLocation = getUpdatedLocation(recievedLocation, isDesktop);

    const updatedLocation: LocationType = {
      ...location,
      // @ts-ignore
      ...(recievedLocation.view && (
        {
          // @ts-ignore
          panel: STRUCTURE.VIEWS[recievedLocation.view].DEFAULT_PANEL,
          // @ts-ignore
          modal: STRUCTURE.VIEWS[recievedLocation.view].DEFAULT_MODAL,
        }
      )),
      ...recievedLocation,
    };

    if (!compareStringifiedObjects(location, updatedLocation)) {
      updatedLocation.length += 1;
      window.history.pushState(updatedLocation, '');

      if (!isSilent) {
        await dispatch(updateLocation({ location: updatedLocation, handler: handler, isDesktop } ));
      }
    }
  }, [dispatch, isDesktop, location]);

  const back = useCallback(() => window.history.back(), []);

  const replace = useCallback(async (
    recievedLocation: Partial<LocationType>,
    isSilent: boolean = false
  ) => {
    const updatedLocation = {
      ...location,
      ...getUpdatedLocation(recievedLocation, isDesktop),
    };

    window.history.replaceState(updateLocation, '');

    if (!isSilent) {
      await dispatch(updateLocation({ location: updatedLocation, isDesktop }));
    }
  }, [dispatch, isDesktop, location]);

  useEffect(() => {
    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState);
  }, [onPopState]);

  return {
    forward,
    back,
    replace,
  };
};

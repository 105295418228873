import React from 'react';

import { DesktopResultContent, MobileResultContent } from './contents';
import { ResultContentProps } from './types';

export const ResultContent: React.FC<ResultContentProps> = ({
  isDesktop,
  isOtherUserBeenPredicted,
  temperamentData,
  ...restProps
}) => {
  const title = isOtherUserBeenPredicted ? `Тип пользователя — ${temperamentData.name}` : `Ваш тип — ${temperamentData.name}`;

  return isDesktop ? (
    <DesktopResultContent
      isDesktop={isDesktop}
      title={title}
      isOtherUserBeenPredicted={isOtherUserBeenPredicted}
      temperamentData={temperamentData}
      {...restProps}
    />
  ) : (
    <MobileResultContent
      isDesktop={isDesktop}
      temperamentData={temperamentData}
      isOtherUserBeenPredicted={isOtherUserBeenPredicted}
      title={title}
      {...restProps}
    />
  );
};

import React, { ChangeEvent, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { SearchFriendPanel } from './SearchFriendPanel';
import { SearchFriendPanelContainerProps } from './types';
import { CONTENT_ITEMS } from '../../../../constants/Content';
import { POPOUT } from '../../../../constants/Popout';
import { useGetFriends } from '../../../../hooks/useGetFriends';
import { usePopout, useSetPopoutAlert } from '../../../../hooks/usePopout';
import { useRouter } from '../../../../hooks/useRouter';
import { useSetSelectedFriend } from '../../../../hooks/useSetSelectedFriend';
import { selectIsDesktop } from '../../../../store/settingsSlice/settingsSelectors';
import { selectUserAccessToken } from '../../../../store/userSlice/userSelectors';

const SearchFriendPanelContainer: React.FC<SearchFriendPanelContainerProps> = (props) => {
  const router = useRouter();
  const isDesktop = useSelector(selectIsDesktop);
  const accessToken = useSelector(selectUserAccessToken);
  const [users, setUsers] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [usersToShow, setUsersToShow] = useState<any>(users);
  const [searchText, setSearchText] = useState('');
  const getFriends = useGetFriends();
  const setSelectedFriend = useSetSelectedFriend();
  const setPopoutAlert = useSetPopoutAlert();
  const { setPopout, resetPopout } = usePopout();

  useEffect(() => {
    if (users) {
      // TODO: Сделать ленивую загрузку, не отображать все сразу
      setUsersToShow(!Boolean(searchText) ? users : users.filter((user: any) => (
        user.name.toLowerCase().includes(searchText.toLowerCase()))
      ));
    } else {
      setUsersToShow([]);
    }
  }, [searchText, users]);

  useEffect(() => {
    const getAndSetFriends = async () => {
      if (accessToken) {
        const uncheckedUsers = await getFriends(accessToken);
        setUsers(uncheckedUsers.filter(({ isClosed }: any) => !Boolean(isClosed)));
      }
      setLoading(false);
    };

    setLoading(true);
    getAndSetFriends();
  }, [accessToken]);

  const onFriendClick = async (friendVkId: number) => {
    if (friendVkId) {
      setPopout(POPOUT.SMART_SPINNER);
      await setSelectedFriend(friendVkId);
      resetPopout();
      router.forward({
        content: CONTENT_ITEMS.AFTER_USER_CHOOSE
      });
    } else {
      setPopoutAlert(
        'Внутренняя ошибка сервиса',
        'Данные о пользователе не найдены. Попробуйте выбрать пользователя еще раз',
        () => router.back
      );
    }
  };

  const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <SearchFriendPanel
      onBackClick={router.back}
      isDesktop={isDesktop}
      loading={loading}
      onSearchTextChange={onSearchTextChange}
      onFriendClick={onFriendClick}
      usersToShow={usersToShow}
      searchText={searchText}
      {...props}
    />
  );
};

export default SearchFriendPanelContainer;

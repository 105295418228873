import { SettingsStateType } from './types';
import { getLaunchParams } from '../../utils/getLaunchParams';

export const settingsInitialState: SettingsStateType = {
  data: {
    appearance: 'light',
    platform: 'android',
    isDesktop: false,
    hasHeader: true,
    launchParams: getLaunchParams(),
  },
  loading: false,
  error: null,
};

export const PANEL = {
  PREDICTION: 'prediction',
  SEARCH_FRIEND: 'search_friend',
  RESULT: 'result',
  ERROR: 'error',
  ABOUT_US: 'about_us',
  USER_ENTERING: 'user_entering',
  AFTER_USER_CHOOSE: 'after_user_choose'
} as const;

export type PanelType = typeof PANEL[keyof typeof PANEL];

import { useCallback } from 'react';

import bridge from '@vkontakte/vk-bridge';
import { useSelector } from 'react-redux';

import { useAppDispatch } from './useAppDispatch';
import eventsApi from '../api/EventsApi';
import { EVENT_NAME, EVENT_TYPE } from '../constants/Event';
import { selectLocation } from '../store/routerSlice/routerSelectors';
import { selectAppId } from '../store/settingsSlice/settingsSelectors';
import { updateUser } from '../store/userSlice';
import { selectUserGrantedTokenScope } from '../store/userSlice/userSelectors';
import { handleErrorToStore } from '../utils/handleErrorToStore';
import { mergeArrays } from '../utils/mergeArrays';


export const useSetAccessToken = () => {
  const dispatch = useAppDispatch();

  const appId = useSelector(selectAppId);
  const grantedTokenScope = useSelector(selectUserGrantedTokenScope);
  const location = useSelector(selectLocation);

  // Если получен неполный скоуп или пользователь отказался, то возвращается undefined
  return useCallback(async (newTokenScope: string[]) => {
    if (!newTokenScope) {
      newTokenScope = [];
    }

    if (!appId) {
      return;
    }

    const apiProps = {
      app_id: appId,
      scope: newTokenScope.join()
    };

    try {
      const { access_token, scope } = await bridge.send('VKWebAppGetAuthToken', apiProps);
      const receivedScope = typeof scope === 'undefined' ? [] : (scope.split(',') || []);

      if (typeof access_token === 'undefined' || typeof scope === 'undefined') {
        await eventsApi.sendStatistics({
          type: EVENT_TYPE.GO_THROUGH,
          name: EVENT_NAME.ACCESS_TOKEN_ERROR,
          data: {
            message: 'access_token or scope are undefined',
            is_access_token_undefined: typeof access_token === 'undefined',
            is_scope_undefined: typeof scope === 'undefined',
            granted_token_scope: grantedTokenScope,
            new_token_scope: newTokenScope,
            api_props: apiProps,
          },
          route: location,
        });
      }

      if (
        (typeof grantedTokenScope === 'undefined' || !grantedTokenScope.includes('friends'))
          && typeof receivedScope !== 'undefined' && receivedScope.includes('friends')
      ) {
        await eventsApi.sendStatistics({
          type: EVENT_TYPE.GO_THROUGH,
          name: EVENT_NAME.USER_GAVE_ACCESS_TO_FRIENDS,
          data: {
            granted_token_scope: grantedTokenScope,
            received_scope: receivedScope
          },
          route: location,
        });
      }

      if (typeof scope !== 'undefined' && !newTokenScope.every(s => scope.includes(s))) {
        return undefined;
      }

      dispatch(updateUser({
        access_token,
        granted_token_scope: mergeArrays(grantedTokenScope || [], receivedScope),
      }));
      return access_token;
    } catch (e) {
      await eventsApi.sendStatistics({
        type: EVENT_TYPE.GO_THROUGH,
        name: EVENT_NAME.ACCESS_TOKEN_ERROR,
        data: {
          granted_token_scope: grantedTokenScope,
          new_token_scope: newTokenScope,
          api_props: apiProps,
          error: handleErrorToStore(e),
        },
        route: location,
      });
      if (
        typeof e === 'object' && e &&
          'error_data' in e && e['error_data'] && typeof e['error_data'] === 'object' &&
          'error_reason' in e['error_data'] &&
          e['error_data']['error_reason'] === 'User denied'
      ) {
        return undefined;
      }
      throw new Error('REQUEST_ACCESS_TOKEN_REJECTED');
    }
  }, [appId, grantedTokenScope, dispatch, location]);
};

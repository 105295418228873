import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useSetAccessToken } from '../../../../hooks/useSetAccessToken';
import { setPredictionData } from '../../../../store/predictionSlice';
import { selectUserVKID } from '../../../../store/userSlice/userSelectors';

export const useOnGetTemperamentClick = () => {
  const userVkId = useSelector(selectUserVKID);
  const dispatch = useAppDispatch();
  const setAccessToken = useSetAccessToken();

  const onGetMyTemperamentClick = useCallback( () => {
    if (userVkId) {
      // TODO: Проверить сколько параметров у пользователя доступно
      dispatch(setPredictionData({
        vkId: userVkId,
        predictionParams: undefined
      }));
    }
  }, [dispatch, userVkId]);

  const onGetFriendTemperamentClick = useCallback( async () => {
    return await setAccessToken(['friends']);
  }, [setAccessToken]);

  return {
    onGetMyTemperamentClick,
    onGetFriendTemperamentClick
  };
};

import React, { useEffect, useRef } from 'react';

import { MobileIntroContent } from './MobileIntroContent';
import { MobileIntroContentContainerProps } from './types';

const MobileIntroContentContainer: React.FC<MobileIntroContentContainerProps> = ({
  panelOptions,
  showed,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current?.scrollTo({ top: 0 });
  });

  const initialState = new Array(panelOptions.length).fill('none');
  initialState[showed.index] = 'flex';
  const [displaies, setDisplaies] = React.useState(initialState);

  useEffect(() => {
    const newDisplaies = [...displaies];
    newDisplaies[showed.index] = 'flex';
    setDisplaies(newDisplaies);
  }, [showed]);

  const onScrollAnimationComplete = () => {
    const newDisplaies = new Array(displaies.length).fill('none');
    newDisplaies[showed.index] = 'flex';
    setDisplaies(newDisplaies);
  };

  return (
    <MobileIntroContent
      panelOptions={panelOptions}
      showed={showed}
      containerRef={containerRef}
      onScrollAnimationComplete={onScrollAnimationComplete}
      displaies={displaies}
      {...props}
    />
  );
};

export default MobileIntroContentContainer;

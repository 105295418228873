import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { predictionInitialState } from './constants';
import { PredictionDataType } from './types';


const predictionSlice = createSlice({
  name: 'predictionSlice',
  initialState: predictionInitialState,
  reducers: {
    setPredictionData(state, action: PayloadAction<PredictionDataType>) {
      if (state.vkId !== action.payload.vkId || typeof state.predictionParams === 'undefined') {
        state.vkId = action.payload.vkId;
        state.predictionParams = action.payload.predictionParams;
      }
    },
    clearPredictionData(state) {
      state.vkId = undefined;
      state.predictionParams = undefined;
    },
  },
});

export const {
  setPredictionData,
  clearPredictionData,
} = predictionSlice.actions;

export default predictionSlice.reducer;

import React from 'react';

import bridge from '@vkontakte/vk-bridge';
import { useSelector } from 'react-redux';

import { SharingPopout } from './SharingPopout';
import eventsApi from '../../../api/EventsApi';
import { EVENT_NAME, EVENT_TYPE } from '../../../constants/Event';
import { usePopout } from '../../../hooks/usePopout';
import { selectPopoutMetaInfo } from '../../../store/popoutSlice/popoutSelectors';
import { selectLocation } from '../../../store/routerSlice/routerSelectors';
import { selectAppId } from '../../../store/settingsSlice/settingsSelectors';
import { TemperamentType } from '../../../types/temperament.type';
import { deepCopy } from '../../../utils/deepCopy';
import {
  getTemperamentOptions,
  storyTemplate,
  USER_DENIED_ERROR_MESSAGE
} from '../../../views/PredictionView/panels/ResultPanel/components/ResultContent/constants';

const SharingPopoutContainer: React.FC = () => {
  const metaInfo = useSelector(selectPopoutMetaInfo);
  // @ts-ignore
  const temperamentType: TemperamentType = metaInfo.temperamentType;
  // @ts-ignore
  const isOtherUserBeenPredicted: boolean = metaInfo.isOtherUserBeenPredicted;
  const appId = useSelector(selectAppId);
  const location = useSelector(selectLocation);
  const temperamentData = getTemperamentOptions()[temperamentType];
  const { resetPopout } = usePopout();

  const shareOnWall = async () => {
    resetPopout();

    const photo = isOtherUserBeenPredicted ? temperamentData.wallImage.other : temperamentData.wallImage.me;
    const message = isOtherUserBeenPredicted ? (
      `Ваш цифровой темперамент — ${temperamentData.name.toLowerCase()}! Чтобы узнать темперамент своего друга, заходи в приложение «AI темперамент» — https://vk.com/vk_temperament`
    ) : (
      `Мой цифровой темперамент — ${temperamentData.name.toLowerCase()}! Чтобы узнать темперамент себя или своего друга, заходи в приложение «AI темперамент» — https://vk.com/vk_temperament`
    );

    await bridge.send('VKWebAppShowWallPostBox', {
      message,
      attachments: `${photo},https://vk.com/vk_temperament`,
    })
      .then((data) => {
        eventsApi.sendStatistics({
          type: EVENT_TYPE.REPOST,
          name: isOtherUserBeenPredicted ? EVENT_NAME.RESULT_REPOST_OTHER_SUCCESS : EVENT_NAME.RESULT_REPOST_MY_SUCCESS,
          route: location,
        });
      })
      .catch((error) => {
        eventsApi.sendStatistics({
          type: EVENT_TYPE.REPOST,
          name: isOtherUserBeenPredicted ? EVENT_NAME.RESULT_REPOST_OTHER_FAILURE : EVENT_NAME.RESULT_REPOST_MY_FAILURE,
          route: location,
        });
      });
  };

  const shareStory = async () => {
    resetPopout();

    const storyBoxToShow = deepCopy(storyTemplate.story_box);
    storyBoxToShow.url = isOtherUserBeenPredicted ? temperamentData.storyImage.other : temperamentData.storyImage.me;

    await bridge.send('VKWebAppShowStoryBox', storyBoxToShow)
      .then((data) => {
        const event = isOtherUserBeenPredicted ? storyTemplate.successfulOtherEvent : storyTemplate.successfulMyEvent;
        if (event && typeof event === 'object') {
          eventsApi.sendStatistics({ data, route: location, ...event });
        }
      })
      .catch((error) => {
        const event = isOtherUserBeenPredicted ? storyTemplate.failedOtherEvent : storyTemplate.failedMyEvent;
        if (event && typeof event === 'object') {
          eventsApi.sendStatistics({ data: error, route: location, ...event });
        }
        if (error.error_data.error_reason !== USER_DENIED_ERROR_MESSAGE) {
          throw error;
        }
      });
  };

  return (
    <SharingPopout
      shareOnWall={shareOnWall}
      shareStory={shareStory}
      onClose={resetPopout}
    />
  );
};

export default SharingPopoutContainer;

import React, { forwardRef } from 'react';

import { useSelector } from 'react-redux';

import { AfterUserChoose } from './AfterUserChoose';
import { AfterUserChooseContainerProps } from './types';
import { selectSelectedUserData } from '../../../../../../store/FriendSearchSlice/popoutSelectors';

const AfterUserChooseContainer = forwardRef<HTMLDivElement, AfterUserChooseContainerProps>((props, ref) => {
  const user = useSelector(selectSelectedUserData);

  return (
    <AfterUserChoose
      ref={ref}
      friend={user}
      {...props}
    />
  );
});

export default AfterUserChooseContainer;

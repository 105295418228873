import React from 'react';

import { Banner, Button, Card, FormItem, FormLayout, Group, Radio, Spacing, Text, Title } from '@vkontakte/vkui';

import { ContentResultPageProps } from './types';
import { FamousPerson } from '../../FamousPerson';
import { TextContent } from '../../TextContent';

export const MobileResultContent: React.FC<ContentResultPageProps> = ({
  temperamentData,
  hasUserAgreed,
  shareResult,
  onAppWithTestsClick,
  onRadioChange,
  title,
  isOtherUserBeenPredicted
}) => {
  return (
    <Group style={{ padding: 0 }}>
      <Spacing size={24} />

      <div style={{ margin: '0 32px' }}>
        <Text style={{
          fontSize: 16,
          color: 'var(--light-text-secondary, #818C99)',
          textAlign: 'center',
          marginBottom: '8px'
        }}>
                    Результаты готовы!
        </Text>
        <Title level="2" weight="2" style={{ textAlign: 'center' }}>
          {title}
        </Title>
      </div>

      <Spacing size={24} />

      <div style={{ margin: '0 10px' }}>
        {temperamentData.image.mobile}
      </div>

      <Spacing size={24} />

      <div style={{ margin: '0 26px' }}>
        <TextContent text={temperamentData.strongFeatures} title="Сильные стороны" />

        <Spacing size={9} />

        <TextContent text={temperamentData.weakFeatures} title="Слабые стороны" />

        <Spacing size={9} />

        <Title level="2" style={{ fontWeight: 700 }}>
                    Знаменитые представители
        </Title>
      </div>

      <Spacing size={16} />

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', margin: '0 20px' }}>
        {temperamentData.famousPersons.map(({ image, name, subtitle, smallImage }: any) => {
          return (
            <div style={{
              maxWidth: 175,
              width: 'calc(50% - 12px)',
              minWidth: 100,
              margin: '0 6px'
            }}>
              <FamousPerson
                image={image}
                name={name}
                subtitle={subtitle}
                smallImage={smallImage}
              />
            </div>
          );
        })}
      </div>

      <Spacing size={24} />

      <div style={{ margin: '0 16px' }}>
        <Card mode="shadow" style={{ padding: '6px 10px' }}>
          <FormLayout>
            <FormItem top="Согласны ли Вы с результатом?">
              <Radio
                name="radio"
                value="yes"
                checked={typeof hasUserAgreed === 'boolean' && hasUserAgreed}
                onChange={async () => await onRadioChange(true)}
              >
                  Да
              </Radio>
              <Radio
                name="radio"
                value="no"
                checked={typeof hasUserAgreed === 'boolean' && !hasUserAgreed}
                onChange={async () => await onRadioChange(false)}
              >
                  Нет
              </Radio>
            </FormItem>
          </FormLayout>
        </Card>

        <>
          <Spacing size={24} />

          <Button
            stretched
            size="l"
            mode="primary"
            onClick={shareResult}
          >
                      Поделиться результатом
          </Button>
        </>
      </div>

      <Spacing size={16} />

      <div>
        <Banner
          mode="image"
          size="s"
          header="Узнать о темпераменте больше"
          style={{ padding: 12 }}
          subheader={
            <span>
            Пройдите больше тестов
              <br />в нашем приложении
            </span>
          }
          background={
            <div
              style={{
                backgroundColor: '#00121e',
                backgroundImage: 'url(https://sun9-67.userapi.com/impg/1zU56wmpR8t6uD8X0cRbJslOhqezfK5_Db-Xtw/WxtlXibt9HY.jpg?size=200x200&quality=96&sign=436e1a9a901ebf4ed0c868418edf5e71&type=album)',
                backgroundPosition: 'right bottom',
                backgroundSize: 110,
                backgroundRepeat: 'no-repeat',
              }}
            />
          }
          actions={
            <Button appearance="overlay" size="m" onClick={onAppWithTestsClick}>
                            Перейти
            </Button>
          }
        />
      </div>

      <Spacing size={4} />
    </Group>
  );
};

import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { ResultPanel } from './ResultPanel';
import { ResultPanelContainerProps } from './types';
import eventsApi from '../../../../api/EventsApi';
import predictionsApi from '../../../../api/PredictionApi';
import { collectInfo } from '../../../../api/VKWebApi';
import { CONTENT_ITEMS } from '../../../../constants/Content';
import { EVENT_NAME, EVENT_TYPE } from '../../../../constants/Event';
import { PANEL } from '../../../../constants/Panel';
import { VIEW } from '../../../../constants/View';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useSetPopoutAlert } from '../../../../hooks/usePopout';
import { useRouter } from '../../../../hooks/useRouter';
import { useSetAccessToken } from '../../../../hooks/useSetAccessToken';
import { setPredictionData } from '../../../../store/predictionSlice';
import { selectPredictionParams, selectPredictionVkId } from '../../../../store/predictionSlice/predictionSelectors';
import { selectLocation } from '../../../../store/routerSlice/routerSelectors';
import { selectIsDesktop } from '../../../../store/settingsSlice/settingsSelectors';
import { selectUserAccessToken, selectUserVKID } from '../../../../store/userSlice/userSelectors';
import { handleErrorToStore } from '../../../../utils/handleErrorToStore';


const ResultPanelContainer: React.FC<ResultPanelContainerProps> = (props) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isDesktop = useSelector(selectIsDesktop);
  const userVkId = useSelector(selectUserVKID);
  const oldPredictionParams = useSelector(selectPredictionParams);
  const oldAccessToken = useSelector(selectUserAccessToken);
  const predictionVkId = useSelector(selectPredictionVkId);
  const location = useSelector(selectLocation);
  const [resultType, setResultType] = useState<string | undefined>(undefined);
  const setAccessToken = useSetAccessToken();
  const setPopoutAlert = useSetPopoutAlert();

  // TODO: По-хорошему бы разбить
  useEffect(() => {
    const handlePredictionGetting = async () => {
      const tooLongPredictionTimoutId = setTimeout(async () => {
        await eventsApi.sendStatistics({
          type: EVENT_TYPE.PREDICTION,
          name: EVENT_NAME.TOO_LONG_PREDICTION,
          data: { message: 'Ответ генерировался дольше 15 секунд' },
          route: location,
        });
      }, 15000);

      if (predictionVkId && userVkId) {
        let accessToken = oldAccessToken;
        if (!accessToken) {
          try {
            // TODO: Убрать, но, возможно, потом понадобится
            // const startSettingAccessToken = new Date().getTime();
            accessToken = await setAccessToken(predictionVkId === userVkId ? [] : ['friends']);
            // await eventsApi.sendStatistics({
            //   type: EVENT_TYPE.PREDICTION,
            //   name: EVENT_NAME.PREDICTION_STATISTICS,
            //   data: {
            //     message: 'Время установки accessToken',
            //     time: (new Date().getTime()) - startSettingAccessToken,
            //     is_token_undefined: typeof accessToken === 'undefined',
            //   },
            //   route: location,
            // });
          } catch (e) {
            accessToken = undefined;
          }
        }
        if (accessToken) {
          let predictionParams = oldPredictionParams;
          if (!predictionParams) {
            try {
              // TODO: Убрать, но, возможно, потом понадобится
              // const startSettingPredictionParams = new Date().getTime();
              predictionParams = await collectInfo(predictionVkId, accessToken);
              // await eventsApi.sendStatistics({
              //   type: EVENT_TYPE.PREDICTION,
              //   name: EVENT_NAME.PREDICTION_STATISTICS,
              //   data: {
              //     message: 'Время установки predictionParams',
              //     time: (new Date().getTime()) - startSettingPredictionParams,
              //     is_params_undefined: typeof predictionParams === 'undefined',
              //   },
              //   route: location,
              // });
              dispatch(setPredictionData({ vkId: predictionVkId, predictionParams }));
            } catch (error) {
              await eventsApi.sendStatistics({
                type: EVENT_TYPE.VK_API,
                name: EVENT_NAME.USERS_GET_VK_API_ERROR,
                data: {
                  message: 'Ошибка при получении данных для предсказания (handlePredictionGetting)',
                  error: handleErrorToStore(error),
                },
                route: location,
              });
              setPopoutAlert(
                'Ошибка при доступе к данным пользователя',
                'Во время сбора данных для предсказания произошла ошибка. Попробуйте выбрать пользователя еще раз',
                () => { router.back(); }
              );
            }
          }
          if (predictionParams) {
            try {
              // TODO: Убрать, но, возможно, потом понадобится
              // const startSettingPrediction = new Date().getTime();
              const response = await predictionsApi.getPrediction(predictionParams, predictionVkId);
              // await eventsApi.sendStatistics({
              //   type: EVENT_TYPE.PREDICTION,
              //   name: EVENT_NAME.PREDICTION_STATISTICS,
              //   data: {
              //     message: 'Время получения prediction',
              //     time: (new Date().getTime()) - startSettingPrediction
              //   },
              //   route: location,
              // });
              if (
                response &&
                  'payload' in response &&
                  typeof response['payload'] == 'object' &&
                  'temperament' in response['payload'] &&
                  response['payload']['temperament'] &&
                  typeof response['payload']['temperament'] === 'string' &&
                  (response['payload']['temperament'])
              ) {
                setResultType(response['payload']['temperament']);
              } else {
                await eventsApi.sendStatistics({
                  type: EVENT_TYPE.PREDICTION,
                  name: EVENT_NAME.ERROR_DURING_PREDICTION,
                  data: { message: 'Некорректное значение response', response },
                  route: location,
                });
                setPopoutAlert(
                  'Внутренняя ошибка сервиса',
                  'Полученный ответ от сервера не удалось обработать, попробуйте выбрать пользователя еще раз',
                  () => { router.back(); }
                );
              }
            } catch (error) {
              await eventsApi.sendStatistics({
                type: EVENT_TYPE.PREDICTION,
                name: EVENT_NAME.ERROR_DURING_PREDICTION,
                data: handleErrorToStore(error) || { message: 'Неизвестная ошибка' },
                route: location,
              });
              setPopoutAlert(
                'Внутренняя ошибка сервиса',
                'Произошла неизвестная ошибка на стороне сервера, попробуйте выбрать пользователя еще раз',
                () => { router.back(); }
              );
            }
          } else {
            await eventsApi.sendStatistics({
              type: EVENT_TYPE.PREDICTION,
              name: EVENT_NAME.ERROR_DURING_PREDICTION,
              data: {
                message: 'Параметры для предсказания не получены',
                received_prediction_params: (
                  typeof predictionParams === 'undefined' ? 'undefined' : predictionParams
                )
              },
              route: location,
            });
            setPopoutAlert(
              'Ошибка при доступе к данным пользователя',
              'Во время сбора данных для предсказания произошла ошибка. Попробуйте, пожалуйста, перезагрузить приложение.',
              () => { router.back(); }
            );
          }
        } else {
          await eventsApi.sendStatistics({
            type: EVENT_TYPE.PREDICTION,
            name: EVENT_NAME.ERROR_DURING_PREDICTION,
            data: {
              message: 'accessToken не определен',
              is_token_undefined: typeof accessToken === 'undefined'
            },
            route: location,
          });
          setPopoutAlert(
            'Ошибка при доступе к данным пользователя',
            'Во время сбора данных для предсказания произошла ошибка. Попробуйте, пожалуйста, еще раз. Если Вы дали запрет на доступ к "Общей информации", то, пожалуйста, предоставьте разрешение, без него предсказание невозможно.',
            () => { router.back(); }
          );
        }
      } else {
        await eventsApi.sendStatistics({
          type: EVENT_TYPE.PREDICTION,
          name: EVENT_NAME.ERROR_DURING_PREDICTION,
          data: {
            message: 'Пользователь не определен',
            is_user_undefined: typeof userVkId === 'undefined',
            is_prediction_user_undefined: typeof predictionVkId === 'undefined',
          },
          route: location,
        });
        setPopoutAlert(
          'Ошибка при доступе к данным пользователя',
          'Во время сбора данных для предсказания произошла ошибка. Попробуйте, пожалуйста, перезагрузить приложение.',
          () => { router.back(); }
        );
      }

      clearTimeout(tooLongPredictionTimoutId);
    };
    handlePredictionGetting();
  }, [predictionVkId]);

  const onBackClick = () => router.forward({
    view: VIEW.PREDICTION,
    panel: PANEL.PREDICTION,
    content: CONTENT_ITEMS.PREDICTION,
  });

  return (
    <ResultPanel
      onBackClick={onBackClick}
      isDesktop={isDesktop}
      predictionVkId={predictionVkId}
      userVkId={userVkId}
      //@ts-ignore
      resultType={resultType}
      {...props}
    />
  );
};

export default ResultPanelContainer;

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';


export const selectSettings = (state: RootState) => state.settings.data;

export const selectAppearance = createSelector(
  selectSettings,
  (settings) => settings.appearance,
);

export const selectPlatform = createSelector(
  selectSettings,
  (settings) => settings.platform,
);

export const selectIsDesktop = createSelector(
  selectSettings,
  (settings) => settings.isDesktop,
);

export const selectHasHeader = createSelector(
  selectSettings,
  (settings) => settings.hasHeader,
);

export const selectLaunchParams = createSelector(
  selectSettings,
  (settings) => settings.launchParams,
);

export const selectAppId = createSelector(
  selectLaunchParams,
  (launchParams) => launchParams.vk_app_id,
);

import { CONTENT_ITEMS, ContentItemsType } from '../../../../../../constants/Content';

export const getStackedIndex = (content: ContentItemsType) => {
  switch (content) {
    case CONTENT_ITEMS.PREDICTION:
      return 0;
    case CONTENT_ITEMS.USER_ENTERING:
      return 1;
    case CONTENT_ITEMS.AFTER_USER_CHOOSE:
      return 2;
    default:
      return 0;
  }
};

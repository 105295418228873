import api from './api';

function getUser() {
  return api('users');
}

function updateAfterShowingIntroductoryViews() {
  return api('users/update_after_showing_intro', {}, { method: 'PUT' });
}

function getHasAlreadyBeenPurchased(prediction_user_id: number) {
  return api('users/check_purchase', { prediction_user_id });
}

const usersApi = { getUser, updateAfterShowingIntroductoryViews, getHasAlreadyBeenPurchased };

export default usersApi;

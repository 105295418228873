import React from 'react';

import { Banner, Button, Card, FormItem, FormLayout, Group, Radio, Spacing, Text, Title } from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './DesktopResultContent.module.css';
import { ContentResultPageProps } from './types';
import { FamousPerson } from '../../FamousPerson';
import { TextContent } from '../../TextContent';

export const DesktopResultContent: React.FC<ContentResultPageProps> = ({
  temperamentData,
  hasUserAgreed,
  shareResult,
  onAppWithTestsClick,
  onRadioChange,
  title,
}) => {
  return (
    <Group className={styles.container}>
      <Spacing size={32} />
      <div className={styles.upperTextContainer}>
        <Text className={styles.subTitle}>
          Результаты готовы!
        </Text>
        <Title level="2" weight="2" className={styles.title}>
          {title}
        </Title>
      </div>
      <Spacing size={12} />
      <div className={styles.content}>
        <AnimatePresence>
          <motion.div animate={{ scale: [1, 1.02, 1] }} transition={{ duration: 0.3 }}>
            {temperamentData.image.desktop}
          </motion.div>
        </AnimatePresence>
        <Spacing size={32}/>
        <TextContent
          text={(
            <span className={styles.strongFeatures}>
              {temperamentData.strongFeatures}
            </span>
          )}
          title="Сильные стороны"
        />
        <Spacing size={12}/>
        <TextContent
          text={(
            <span className={styles.weakFeatures}>
              {temperamentData.weakFeatures}
            </span>
          )}
          title="Слабые стороны"
        />
        <Spacing size={12}/>
        <Title level="2" className={styles.famousPeopleTitle}>
          Знаменитые представители
        </Title>
        <Spacing size={16}/>
        <div className={styles.famousPeopleContainer}>
          {temperamentData.famousPersons.map(({ image, name, subtitle, smallImage }: any) => {
            return (
              <div className={styles.famousPeopleCellContainer}>
                <FamousPerson
                  image={image}
                  smallImage={smallImage}
                  name={name}
                  subtitle={subtitle}
                />
              </div>
            );
          })}
        </div>
        <Spacing size={32} />
        <div className={styles.agreementContainer}>
          <Card mode="shadow" className={styles.agreementCard}>
            <FormLayout>
              <FormItem top="Согласны ли Вы с результатом?">
                <Radio
                  name="radio"
                  value="yes"
                  checked={typeof hasUserAgreed === 'boolean' && hasUserAgreed}
                  onChange={() => onRadioChange(true)}
                >
                  Да
                </Radio>
                <Radio
                  name="radio"
                  value="no"
                  checked={typeof hasUserAgreed === 'boolean' && !hasUserAgreed}
                  onChange={() => onRadioChange(false)}
                >
                  Нет
                </Radio>
              </FormItem>
            </FormLayout>
          </Card>
          <>
            <Spacing size={20} />
            <Button
              stretched
              size="l"
              mode="primary"
              onClick={shareResult}
            >
              Поделиться результатом
            </Button>
          </>
        </div>
        <Spacing size={16} />
        <div>
          <Banner
            mode="image"
            size="s"
            header="Узнать о темпераменте больше"
            className={styles.banner}
            subheader={(
              <span>
                Пройдите больше тестов
                <br />в нашем приложении
              </span>
            )}
            background={<div className={styles.bannerBackground} />}
            actions={(
              <Button appearance="overlay" size="m" onClick={onAppWithTestsClick}>
                Перейти
              </Button>
            )}
          />
        </div>
      </div>
      <Spacing size={4} />
    </Group>
  );
};

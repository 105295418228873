import { useCallback } from 'react';

import bridge from '@vkontakte/vk-bridge';
import { useSelector } from 'react-redux';

import { useAppDispatch } from './useAppDispatch';
import { usePopout, useSetPopoutAlert } from './usePopout';
import { useRouter } from './useRouter';
import { useSetSelectedFriend } from './useSetSelectedFriend';
import eventsApi from '../api/EventsApi';
import usersApi from '../api/UsersApi';
import { collectInfo } from '../api/VKWebApi';
import { EVENT_NAME, EVENT_TYPE } from '../constants/Event';
import { POPOUT } from '../constants/Popout';
import { selectSelectedUserData } from '../store/FriendSearchSlice/popoutSelectors';
import { setPredictionData } from '../store/predictionSlice';
import { selectLocation } from '../store/routerSlice/routerSelectors';
import { selectUserAccessToken } from '../store/userSlice/userSelectors';
import { handleErrorToStore } from '../utils/handleErrorToStore';

export const useHandlePaymentError = () => {
  const { setPopout, resetPopout } = usePopout();
  const setPopoutAlert = useSetPopoutAlert();
  const router = useRouter();

  const location = useSelector(selectLocation);

  return useCallback(async (eventName: string) => {
    setPopout(POPOUT.SMART_SPINNER);
    await eventsApi.sendStatistics({
      type: EVENT_TYPE.PAYMENT,
      name: eventName,
      data: { message: 'Ошибка во время оплаты' },
      route: location,
    });
    resetPopout();

    setPopoutAlert(
      'Сервер не обработал оплату',
      'Произошла ошибка во время обработки оплаты сервером. Попробуйте перезапустить приложение. Если ошибка не устраняется, свяжитесь с нами по почте mail@dscs.pro.',
      () => {
        router.back();
      }
    );
  }, [location, resetPopout, router, setPopout, setPopoutAlert]);
};

// TODO: Очень много всего, надо разбить
export const useBuyPredictionIfNeeded = (onSuccess: () => void) => {
  const selectedUserData = useSelector(selectSelectedUserData);
  const accessToken = useSelector(selectUserAccessToken);
  const location = useSelector(selectLocation);
  const setPopoutAlert = useSetPopoutAlert();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { setPopout, resetPopout } = usePopout();
  const setSelectedFriend = useSetSelectedFriend();
  const handlePaymentError = useHandlePaymentError();

  return useCallback(async () => {
    if (typeof selectedUserData === 'undefined' || typeof selectedUserData.vkId === 'undefined') {
      setPopoutAlert(
        'Внутренняя ошибка сервиса',
        'Друг оказался не определен. Попробуйте выбрать друга заново',
        () => { router.back(); }
      );
    } else if (typeof accessToken === 'undefined') {
      setPopoutAlert(
        'Внутренняя ошибка сервиса',
        'Токен доступа не определен. Попробуйте перезайти в приложение',
        () => { router.back(); }
      );
    } else {
      try {
        setPopout(POPOUT.SMART_SPINNER);
        const friendInfo = await collectInfo(selectedUserData.vkId, accessToken);
        resetPopout();

        // TODO: Проверить сколько параметров у друга доступно
        dispatch(setPredictionData({
          vkId: selectedUserData.vkId,
          predictionParams: friendInfo
        }));

        if (selectedUserData.isSelectedUserPurchased) {
          onSuccess();
        } else if (friendInfo) {
          bridge.send('VKWebAppShowOrderBox', {
            type: 'item',
            item: `friend_${selectedUserData.vkId}`
          })
            .then(async (data) => {
              if (typeof selectedUserData.vkId === 'undefined') {
                await handlePaymentError(EVENT_NAME.USER_VK_ID_NOT_FOUND_DURING_PAYMENT);
                return;
              }

              // TODO: Временное решение. По идее,
              //  ВК должен уведомлять об успешной оплате после обработки оплаты сервером приложения.
              //  Однако ВК уведомляет сразу после оплаты пользователем,
              //  поэтому необходимо отдельно ждать положительного ответа от сервера.
              //  N.B. Дополнительную проверку со стороны сервера все равно нужно проводить,
              //  так как ответ от ВК можно подделать.
              setPopout(POPOUT.SMART_SPINNER);
              let isPaymentSuccessfullyProcessed = false;

              const checkPurchaseIntervalId = setInterval(async () => {
                // @ts-ignore
                const { has_friend_already_been_purchased } = await usersApi.getHasAlreadyBeenPurchased(selectedUserData.vkId);
                isPaymentSuccessfullyProcessed = has_friend_already_been_purchased;
                if (isPaymentSuccessfullyProcessed) {
                  resetPopout();
                  if (data && selectedUserData.vkId) {
                    await setSelectedFriend(selectedUserData.vkId, true);
                    onSuccess();
                  }
                  clearInterval(checkPurchaseIntervalId);
                }
              }, 2000);

              setTimeout(async () => {
                clearInterval(checkPurchaseIntervalId);

                if (!isPaymentSuccessfullyProcessed) {
                  resetPopout();
                  await handlePaymentError(EVENT_NAME.DID_NOT_RECEIVE_RESPONSE_FROM_SERVER);
                }
              }, 90000);
            })
            .catch((error) => {
              let reason;

              if (
                error &&
                  'error_data' in error &&
                  error.error_data &&
                  'error_reason' in error.error_data
              ) {
                reason = error.error_data.error_reason;
              }

              if (reason !== 'User denied') {
                setPopoutAlert(
                  'Ошибка во время оплаты',
                  'Произошла ошибка во время оплаты, голоса не спишутся. Попробуйте выбрать пользователя еще раз',
                  () => { router.back(); }
                );
              }
            });
        }
      } catch (error) {
        await eventsApi.sendStatistics({
          type: EVENT_TYPE.VK_API,
          name: EVENT_NAME.USERS_GET_VK_API_ERROR,
          data: {
            message: 'Ошибка при получении данных для предсказания (useBuyPredictionIfNeeded)',
            error: handleErrorToStore(error),
          },
          route: location,
        });
        setPopoutAlert(
          'Ошибка при доступе к данным пользователя',
          'Во время сбора данных для предсказания произошла ошибка. Попробуйте выбрать пользователя еще раз',
          () => { router.back(); }
        );
      }
    }
  }, [router, accessToken, dispatch, selectedUserData, onSuccess, setPopoutAlert, location]);
};

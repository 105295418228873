export const getHasProperty = (data: any, property: string) => {
  return Boolean(property in data && data[property]);
};

export const getAge = (userData: any) => {
  let bdateProperty;

  if (getHasProperty(userData, 'bdate') && typeof userData['bdate'] === 'string') {
    bdateProperty = userData['bdate'];
  } else {
    return undefined;
  }

  const birthdayParts = bdateProperty.match(/(\d+)/g);

  if (birthdayParts && birthdayParts.length > 2) {
    const birthday = new Date(
      Number(birthdayParts[2]),
      Number(birthdayParts[1]) - 1,
      Number(birthdayParts[0])
    );
    const msDiff = new Date().getTime() - birthday.getTime();
    return Math.abs(new Date(msDiff).getUTCFullYear() - 1970);
  }

  return undefined;
};

export const getHasMobilePhone = (userData: any) => {
  let mobilePhone;
  if (
    getHasProperty(userData, 'contacts') &&
      getHasProperty(userData['contacts'], 'mobile_phone')
  ) {
    mobilePhone = userData['contacts']['mobile_phone'];
  }

  return Boolean(
    mobilePhone &&
        typeof mobilePhone === 'string' &&
        mobilePhone.length >= 11
  );
};

export const getNumberProperty = (userData: any, property: string) => {
  return getHasProperty(userData, property) ? Number(userData[property]) : undefined;
};

export const getSex = (userData: any) => {
  if (!getHasProperty(userData, 'sex')) {
    return 2;
  }

  return userData['sex'] === 0 ? 2 : userData['sex'];
};

export const getHasArrayProperty = (userData: any, property: string) => {
  return (
    getHasProperty(userData, property) &&
      Boolean(userData[property].toString()) &&
      userData[property].toString() !== '[]'
  );
};


import React from 'react';

import { View } from '@vkontakte/vkui';

import { DesktopAboutUs } from './panels/DesktopAboutUs';
import { DesktopAfterUserChoose } from './panels/DesktopAfterUserChoose';
import { DesktopUserEntering } from './panels/DesktopUserEntering';
import { PredictionPanel } from './panels/PredictionPanel';
import { ResultPanel } from './panels/ResultPanel';
import { SearchFriendPanel } from './panels/SearchFriendPanel';
import { PredictionViewProps } from './types';
import { PANEL } from '../../constants/Panel';


export const PredictionView: React.FC<PredictionViewProps> = ({ id, activePanel }) => {
  return (
    <View id={id} activePanel={activePanel}>
      <PredictionPanel id={PANEL.PREDICTION} />
      <SearchFriendPanel id={PANEL.SEARCH_FRIEND} />
      <ResultPanel id={PANEL.RESULT} />
      <DesktopAboutUs id={PANEL.ABOUT_US} />
      <DesktopUserEntering id={PANEL.USER_ENTERING} />
      <DesktopAfterUserChoose id={PANEL.AFTER_USER_CHOOSE} />
    </View>
  );
};

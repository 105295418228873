export const INTRO_SLIDES_DATA = [
  {
    title: 'Хотите узнать свой тип темперамента?',
    description: 'Темперамент — это природная основа характера, определяющая его врождённые свойства.'
  },
  {
    title: '4 типа темперамента',
    description: 'Существует 4 типа темперамента. Обычно, один тип доминирует.',
  },
  {
    title: 'Узнать сильные и слабые стороны',
    description: 'Каждый тип темперамента имеет свои слабые и сильные стороны.'
  },
  {
    title: 'Применить искусственный интеллект',
    description: 'Наше приложение покажет, что страницы пользователей говорят о типе их темперамента.'
  }
];

export const ABOUT_DATA = [
  'Темперамент — это природная основа характера, определяющая его врождённые свойства, такие как степень эмоциональности, импульсивности, общительности и т.д.',
  'Существует 4 типа темперамента: сангвиник, холерик, флегматик и меланхолик. И хотя ряд учёных считает, что в чистом виде темпераменты встречаются редко, и большинству из нас характерен смешанный темперамент, какой‑то тип всё равно является доминирующим.',
  'Каждый тип темперамента имеет свои слабые и сильные стороны. «Плюсы» необходимо осознавать и пользоваться ими в жизни, а «минусы» — принимать и держать под контролем.',
  'Наше приложение с помощью искусственного интеллекта покажет, что ваша страница или страница вашего друга в социальной сети говорит о типе темперамента. Хотите попробовать? Просто введите ссылку на интересующую страницу.'
];

export const MOBILE_INTRO_PANELS_OPTIONS = [
  {
    title: 'Хотите узнать свой тип темперамента?',
    text: ABOUT_DATA[0],
    buttonText: 'Далее'
  },
  {
    title: '4 типа темперамента',
    text: ABOUT_DATA[1],
    buttonText: 'Далее'
  },
  {
    title: 'Узнать сильные и слабые стороны',
    text: ABOUT_DATA[2],
    buttonText: 'Далее'
  },
  {
    title: 'Применить искусственный интеллект',
    text: ABOUT_DATA[3],
    buttonText: 'Узнать тип темперамента'
  },
];

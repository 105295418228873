import React, { useEffect } from 'react';

import { motion } from 'framer-motion';


import { variants } from './constants';
import { StackedScreensPropsType } from './types';
import { getArrayWithChangedValue } from '../../utils/getArrayWithChangedValue';

export const StackedScreens: React.FC<StackedScreensPropsType> = ({
  width,
  screens,
  showedData,
  screenIds,
  duration = 0.3,
}) => {
  const [prev, setPrev] = React.useState(0);
  const initialState = new Array(screens.length).fill('none');
  initialState[showedData.index] = 'flex';
  const [displaies, setDisplaies] = React.useState(initialState);

  useEffect(() => {
    const newDisplaies = getArrayWithChangedValue(displaies, 'flex', showedData.index);
    setDisplaies(newDisplaies);
  }, [showedData]);

  return (
    <div style={{ width }}>
      {screens.map((screen, index) => {
        let type = 'hidden';
        if (index === showedData.index) {
          type = showedData.isBack ? 'showIsBack' : 'showIsForward';
        } else if (index === prev) {
          type = showedData.isBack ? 'closedIsBack' : 'closedIsForward';
          setTimeout(() => setPrev(showedData.index), duration * 1000);
        }

        return (
          <motion.div
            key={screenIds[index]}
            style={{
              width,
              position: 'absolute',
              transformOrigin: 'top center',
              display: displaies[index],
            }}
            animate={type}
            variants={variants}
            onAnimationComplete={() => {
              const newDisplaies = getArrayWithChangedValue(
                displaies,
                index === showedData.index ? 'flex' : 'none',
                index
              );
              setDisplaies(newDisplaies);
            }}
            transition={{ duration }}
          >
            {screens[index]}
          </motion.div>
        );
      })}
    </div>
  );
};

import React from 'react';

import { Panel, PanelHeader, PanelHeaderBack, Spacing, Text, Title } from '@vkontakte/vkui';

import { PredictionLoading } from './components/PredictionLoading';
import { ResultContent } from './components/ResultContent';
import { ResultPanelProps } from './types';
import { HeaderContent } from '../../../../components/HeaderContent';

export const ResultPanel: React.FC<ResultPanelProps> = ({
  id,
  onBackClick,
  isDesktop,
  predictionVkId,
  userVkId,
  resultType
}) => {
  if (!predictionVkId) {
    return (
      <Panel id={id} style={{ display: 'flex', height: '100%', width: '100%' }}>
        <PanelHeader before={isDesktop ? <PanelHeaderBack onClick={onBackClick}/> : <HeaderContent needBackIcon title="Назад" onClick={onBackClick}/>} separator={isDesktop}>
          {isDesktop && 'AI темперамент'}
        </PanelHeader>
        <Spacing size={4} />
        <Title style={{ textAlign: 'center' }}>
            Отсутствуют данные для предсказания 😞
        </Title>
        <Spacing size={8} />
        <Text style={{ textAlign: 'center' }}>
          Попробуйте выбрать пользователя еще раз
        </Text>
      </Panel>
    );
  }

  return (
    <Panel id={id} style={!resultType ? { display: 'flex', height: '100%', width: '100%' } : {}}>
      <PanelHeader before={resultType ? (isDesktop ? <PanelHeaderBack onClick={onBackClick}/> : <HeaderContent needBackIcon title="Назад" onClick={onBackClick}/>) : undefined} separator={isDesktop}>
        {isDesktop && 'AI темперамент'}
      </PanelHeader>
      {
        !resultType ? (
          <PredictionLoading />
        ) : (
          <ResultContent
            onClick={() => {}}
            resultType={resultType}
            isOtherUserBeenPredicted={userVkId !== predictionVkId}
          />
        )
      }
    </Panel>
  );
};

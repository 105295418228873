import React from 'react';

import { Icon28ArrowLeftOutline } from '@vkontakte/icons';
import { Title } from '@vkontakte/vkui';

export type HeaderContentPropsType = {
    onClick: () => void;
    title: string;
    needBackIcon: boolean;
}

export const HeaderContent: React.FC<HeaderContentPropsType> = ({ onClick, title, needBackIcon }) => {
  return (
    <div onClick={onClick} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {
        needBackIcon && <Icon28ArrowLeftOutline style={{ marginLeft: 10 }}/>
      }
      <Title style={{ fontSize: 23, marginBottom: 2, marginLeft: needBackIcon ? 22 : 12, color: 'var(--light-text-primary)' }}>{title}</Title>
    </div>
  );
};

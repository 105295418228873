import React from 'react';

import {
  AppRoot,
  Epic,
  ModalRoot,
  PanelHeader,
  SplitCol,
  SplitLayout,
} from '@vkontakte/vkui';

import styles from './MainViews.module.css';
import { PredictionView } from './PredictionView';
import { SearchModal } from './PredictionView/modals';
import { MainViewsProps } from './types';
import { MODAL } from '../constants/Modal';
import { VIEW } from '../constants/View';
import { usePopout } from '../hooks/usePopout';

export const MainViews: React.FC<MainViewsProps> = ({
  isDesktop,
  hasHeader,
  activeView,
  activePanel,
  activeModal,
  onModalClose,
}) => {
  const modal = (
    <ModalRoot
      activeModal={activeModal}
      onClose={onModalClose}
    >
      <SearchModal id={MODAL.MODAL_SEARCH} />
    </ModalRoot>
  );

  const { popout } = usePopout();

  return (
    <AppRoot>
      <SplitLayout
        className={styles.MainViews}
        header={hasHeader && <PanelHeader separator={false} />}
        popout={popout}
        modal={modal}
      >
        <SplitCol animate={!isDesktop}>
          <Epic activeStory={activeView}>
            <PredictionView id={VIEW.PREDICTION} />
          </Epic>
        </SplitCol>
      </SplitLayout>
    </AppRoot>
  );
};

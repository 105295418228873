import React from 'react';

import { useSelector } from 'react-redux';

import { PopoutAlert } from './PopoutAlert';
import { selectPopoutMetaInfo } from '../../../store/popoutSlice/popoutSelectors';


export const TokenScopeAlertContainer: React.FC = () => {
  const { title, description, onClick, buttonText } = useSelector(selectPopoutMetaInfo);

  return (
    <PopoutAlert
      //@ts-ignore
      description={description ? description : 'Произошла неизвестная ошибка :('}
      title={title && typeof title === 'string' ? title : 'Неизвестная ошибка'}
      buttonText={buttonText && typeof buttonText === 'string' ? buttonText : 'Назад'}
      //@ts-ignore
      onClick={onClick && typeof onClick === 'function' ? onClick : (() => {})}
    />
  );
};

export default TokenScopeAlertContainer;

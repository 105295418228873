import React from 'react';

import { Alert } from '@vkontakte/vkui';

import { DEFAULT_DESCRIPTION } from './constants';
import styles from './PopoutAlert.module.css';
import { TokenScopeAlertPropsType } from './types';

export const PopoutAlert: React.FC<TokenScopeAlertPropsType> = ({
  description,
  title,
  onClick,
  buttonText
}) => {
  return (
    <Alert
      header={title}
      text={(
        <span className={styles.description}>
          {description ? description : DEFAULT_DESCRIPTION}
        </span>
      )}
      onClose={onClick ? onClick : (() => {})}
      actions={[{
        title: buttonText || 'Назад',
        mode: 'default',
        action: onClick
      }]}
    />
  );
};

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { convertToUTF } from '../../utils/convertToUTF';
import { RootState } from '../index';


export const selectUser = (state: RootState) => state.user.data;

export const selectUserVKID = createSelector(
  selectUser,
  (user) => user?.id,
);

export const selectUserFirstName = createSelector(
  selectUser,
  (user) => {
    if (user?.first_name) {
      return convertToUTF(user.first_name);
    }
  },
);

export const selectUserLastName = createSelector(
  selectUser,
  (user) => {
    if (user?.last_name) {
      return convertToUTF(user.last_name);
    }
  },
);

export const selectUserFirstEntryDate = createSelector(
  selectUser,
  (user) => {
    if (user?.created_at) {
      return dayjs(user.created_at).format('DD.MM.YYYY');
    }
  },
);

export const selectUserPhoto = createSelector(
  selectUser,
  (user) => {
    if (user?.photo_200) {
      return user.photo_200;
    }
    if (user?.photo_100) {
      return user.photo_100;
    }
  },
);

export const selectUserOriginalPhoto = createSelector(
  selectUser,
  (user) => {
    if (user?.photo_max_orig) {
      return user.photo_max_orig;
    }
  },
);

export const selectUserFullName = createSelector(
  selectUserFirstName,
  selectUserLastName,
  (firstName, lastName) => `${firstName || ''} ${lastName || ''}`.trim(),
);

export const selectUserGrantedTokenScope = createSelector(
  selectUser,
  (user) => user?.granted_token_scope,
);

// export const selectHasRequiredTokenScope = createSelector(
//   selectUserGrantedTokenScope,
//   selectTestTokenScope,
//   (grantedTokenScope = [], requiredTokenScope = []) =>
//     isSubarray(requiredTokenScope, grantedTokenScope),
// );

export const selectUserAccessToken = createSelector(
  selectUser,
  (user) => user?.access_token,
);

export const selectLoading = (state: RootState) => state.user.loading;

export const selectError = (state: RootState) => state.user.error;

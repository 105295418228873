import { connect } from 'react-redux';

import { PredictionView } from './PredictionView';
import { RootState } from '../../store';
import { selectPanel } from '../../store/routerSlice/routerSelectors';


function mapStateToProps(state: RootState) {
  return {
    activePanel: selectPanel(state),
  };
}

export default connect(mapStateToProps)(PredictionView);

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';


export const selectRouter = (state: RootState) => state.router.data;

export const selectLocation = createSelector(
  selectRouter,
  (router) => router.location,
);

export const selectView = createSelector(
  selectLocation,
  (location) => location.view,
);

export const selectPanel = createSelector(
  selectLocation,
  (location) => location.panel,
);

export const selectModal = createSelector(
  selectLocation,
  (location) => location.modal,
);

export const selectContent = createSelector(
  selectLocation,
  (location) => location.content,
);

export const selectLength = createSelector(
  selectLocation,
  (location) => location.length,
);

export const selectOperation = createSelector(
  selectRouter,
  (router) => router.operation,
);

export const selectHandler = createSelector(
  selectRouter,
  (router) => router.handler,
);

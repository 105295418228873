import React from 'react';

import {
  Button,
  Group,
  Spacing,
  Text,
  Title,
} from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import { MobileIntroContentPropsType } from './types';
import { HorizontalScroll } from '../../../../../../components/HorizontalScroll';
import { IntroCoverSVG } from '../../../../../../images/mobile/IntroCoverSVG';


export const MobileIntroContent: React.FC<MobileIntroContentPropsType> = ({
  panelOptions,
  showed,
  onNextAboutClick,
  delayAnimation = 0,
  containerRef,
  onScrollAnimationComplete,
  displaies
}) => {
  const blockWidth = window.innerWidth;
  const maxHeightOfCover = window.innerWidth > window.innerHeight ? '66vh' : '45vh';

  return (
    <div ref={containerRef}>
      <Group>
        <div style={{ minHeight: 'calc(100vh - 170px)' }}>
          <div style={{ padding: '16px 16px 0px 16px' }}>
            <AnimatePresence>
              <motion.div
                animate={{ scale: [0.95, 1.02, 1] }}
                transition={{
                  duration: 0.6,
                  delay: delayAnimation
                }}
              >
                <IntroCoverSVG
                  width="100%"
                  style={{ maxHeight: maxHeightOfCover }}
                />
              </motion.div>
            </AnimatePresence>
            <Spacing size={28} />
          </div>

          <HorizontalScroll
            showedData={showed}
            offsetWidth={blockWidth}
            vignetteWidth={20}
            onAnimationComplete={onScrollAnimationComplete}
          >
            {panelOptions.map((option, index) => {
              return displaies[index] === 'none' ? (
                <div
                  key={`null-${index}`}
                  style={{
                    width: blockWidth,
                    height: 0,
                    flexShrink: 0
                  }}
                />
              ) : (
                <div
                  key={`content-${index}`}
                  style={{ margin: '0px 26px', width: blockWidth - 52, flexShrink: 0 }}
                >
                  <Title level="2" style={{ fontWeight: 700 }}>
                    {option.title}
                  </Title>
                  <Spacing size={12} />
                  <Text style={{ fontSize: 15, color: 'var(--vkui--color_text_subhead)' }}>
                    {option.text}
                  </Text>
                </div>
              );
            })}
          </HorizontalScroll>

        </div>
        <div style={{ padding: '20px 26px 26px' }}>
          <Button
            onClick={onNextAboutClick}
            mode={showed.index === panelOptions.length - 1 ? 'primary' : 'outline'}
            stretched
            size="l"
          >
            {panelOptions[showed.index].buttonText}
          </Button>
        </div>
      </Group>
    </div>
  );
};

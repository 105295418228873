import React from 'react';

import { Button, Spacing, Text, Title } from '@vkontakte/vkui';

import { ContentCardPropsType } from './types';

export const ContentCard: React.FC<ContentCardPropsType> = ({ title, text, buttonText, onClick }) => {
  return (
    <div>
      <Title level="2" weight="2" style={{ textAlign: 'center', marginBottom: '8px' }}>
        {title}
      </Title>
      <Text style={{ fontSize: 16, color: 'var(--light-text-secondary, #818C99)', textAlign: 'center', marginBottom: '8px' }}>
        {text}
      </Text>

      <Spacing size={16} style={{ marginBottom: '8px' }}/>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button size="l" mode="primary" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

import api from './api';

function getPrediction(userParams: { [key: string]: any }, userId: number) {
  return api('predictions',{ params: userParams, prediction_user_id: userId });
}

function updateHasUserAgreed(prediction_user_id: number, params: any, has_user_agreed: boolean) {
  return api(
    'predictions/update_has_user_agreed',
    {
      prediction_user_id,
      params,
      has_user_agreed
    },
    { method: 'PUT' }
  );
}

const predictionsApi = { getPrediction, updateHasUserAgreed };

export default predictionsApi;

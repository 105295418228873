export const handleErrorToStore = (error: any) => {
  if (error instanceof Error) {
    return error.toString();
  }

  if (['object', 'string'].includes(typeof error)) {
    return error;
  }

  return 'Неизвестная ошибка';
};

import React from 'react';

import { Icon28Done } from '@vkontakte/icons';
import { Snackbar } from '@vkontakte/vkui';

import { SuccessSnackbarProps } from './types';

export const SuccessSnackbar: React.FC<SuccessSnackbarProps> = ({
  message,
  duration,
  onSnackbarClose
}) => {
  return (
    <Snackbar
      onClose={onSnackbarClose}
      duration={duration}
      before={<Icon28Done fill='var(--vkui--color_icon_positive--active)' />}
    >
      {message}
    </Snackbar>
  );
};

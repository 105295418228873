import React, { useEffect, useRef } from 'react';

import bridge from '@vkontakte/vk-bridge';
import { useSelector } from 'react-redux';

import { TABS_COUNT } from './constants';
import { PredictionPanel } from './PredictionPanel';
import { PredictionPanelContainerProps } from './types';
import { getStackedIndex } from './utils';
import eventsApi from '../../../../../../api/EventsApi';
import { MOBILE_INTRO_PANELS_OPTIONS } from '../../../../../../constants/AboutData';
import { CONTENT_ITEMS } from '../../../../../../constants/Content';
import { EVENT_NAME, EVENT_TYPE } from '../../../../../../constants/Event';
import { PANEL } from '../../../../../../constants/Panel';
import { POPOUT } from '../../../../../../constants/Popout';
import { VIEW } from '../../../../../../constants/View';
import { useBuyPredictionIfNeeded } from '../../../../../../hooks/useBuyPredictionIfNeeded';
import { usePopout, useSetPopoutAlert } from '../../../../../../hooks/usePopout';
import { useRouter } from '../../../../../../hooks/useRouter';
import { selectContent, selectHandler, selectLocation } from '../../../../../../store/routerSlice/routerSelectors';
import { selectUserGrantedTokenScope } from '../../../../../../store/userSlice/userSelectors';
import { useOnGetTemperamentClick } from '../../hooks';

const PredictionPanelContainer: React.FC<PredictionPanelContainerProps> = (props) => {
  const router = useRouter();
  const { setPopout, resetPopout } = usePopout();

  const goToFriendSearch = () => router.forward({ view: VIEW.PREDICTION, panel: PANEL.SEARCH_FRIEND });
  const setPopoutAlert = useSetPopoutAlert();

  const grantedTokenScope = useSelector(selectUserGrantedTokenScope);
  const content = useSelector(selectContent);
  const handler = useSelector(selectHandler);
  const {
    onGetMyTemperamentClick,
    onGetFriendTemperamentClick
  } = useOnGetTemperamentClick();

  const predictionContentRef = useRef<HTMLDivElement>(null);
  const friendTemperamentRef = useRef<HTMLDivElement>(null);
  const afterUserChooseRef = useRef<HTMLDivElement>(null);
  const location = useSelector(selectLocation);

  let showedStackedIndex = getStackedIndex(content);

  const [showedAbout, setShowedAbout] = React.useState<{index: number; type: string | null}>({
    index: 0,
    type: null
  });
  const [showed, setShowed] = React.useState<{index: number; type: string | null}>({
    index: 0,
    type: null
  });
  const [showedStacked, setShowedStacked] = React.useState<{index: number; isBack: boolean}>({
    index: showedStackedIndex,
    isBack: true
  });

  const initialState = new Array(TABS_COUNT).fill('none');
  initialState[0] = 'flex';
  const [displaies, setDisplaies] = React.useState(initialState);

  useEffect(() => {
    const newDisplaies = [...displaies];
    newDisplaies[showed.index] = 'flex';
    setDisplaies(newDisplaies);
  }, [showed]);

  const handleAboutTab = () => {
    if (showed.index === 0) {
      setShowedAbout({
        index: 0,
        type: 'back'
      });
      router.forward({
        content: CONTENT_ITEMS.ABOUT_US
      });
    }
  };

  const handlePredictionTab = () => {
    if (showed.index === 1) {
      router.back(); // Только для такой структуры
    }
  };

  const onNextAboutClick = () => {
    if (showedAbout.index === MOBILE_INTRO_PANELS_OPTIONS.length - 1) {
      router.back();
    } else {
      setShowedAbout({
        index: showedAbout.index + 1,
        type: 'forward'
      });
    }
  };

  const needShowHeaderBack = (content === CONTENT_ITEMS.ABOUT_US && showedAbout.index !== 0) || (content !== CONTENT_ITEMS.PREDICTION);

  const handleBack = () => {
    router.back();
  };

  useEffect(() => {
    if (content === CONTENT_ITEMS.ABOUT_US) {
      setShowed({ index: 1, type: handler === 'none' ? 'forward' : handler });
      setShowedAbout({
        type: handler === 'none' ? 'forward' : handler,
        index: 0,
      });
    } else {
      setShowed({ index: 0, type: handler === 'none' ? 'forward' : handler });
      setShowedStacked({
        isBack: handler === 'back',
        index: getStackedIndex(content)
      });
    }
  }, [content, handler]);

  const onScrollAnimationComplete = () => {
    const newDisplaies = new Array(displaies.length).fill('none');
    newDisplaies[showed.index] = 'flex';
    setDisplaies(newDisplaies);
  };

  const handleOnGetTemperament = async () => {
    setPopout(POPOUT.SMART_SPINNER);
    await eventsApi.sendStatistics({
      type: EVENT_TYPE.GO_THROUGH,
      name: EVENT_NAME.USER_CLICKED_CHECK_ME_BUTTON,
      data: { message: 'Clicked on mobile' },
      route: location,
    });
    resetPopout();

    onGetMyTemperamentClick();
    router.forward({ panel: PANEL.RESULT });
  };

  const onGetToken = async () => {
    const accessToken = await onGetFriendTemperamentClick();
    if (accessToken) {
      router.forward({
        location: { content: CONTENT_ITEMS.USER_ENTERING },
        handler: 'forward'
      });
    } else {
      setPopoutAlert(
        'Произошла ошибка при получении доступа',
        'При работе сервиса произошла ошибка, попробуйте выбрать нажать на кнопку "Узнать темперамент" еще раз',
      );
    }
  };

  const handleOnGetFriendTemperament = async () => {
    setPopout(POPOUT.SMART_SPINNER);
    await eventsApi.sendStatistics({
      type: EVENT_TYPE.GO_THROUGH,
      name: EVENT_NAME.USER_CLICKED_CHECK_SOMEONE_BUTTON,
      data: { message: 'Clicked on mobile' },
      route: location,
    });
    resetPopout();

    // TODO: Временное
    // setPopoutAlert(
    //   'Функциональность недоступна',
    //   'На текущий момент приложение находится в полу-тестовом режиме и проходит модерацию со стороны ВК. Данная функциональность содержит оплату голосами, а она недоступна без прохождения модерации.',
    //   () => {},
    //   'Хорошо'
    // );

    setPopout(POPOUT.SMART_SPINNER);
    const { platform } = await bridge.send('VKWebAppGetClientVersion');
    resetPopout();

    if (platform === 'ios') {
      setPopoutAlert(
        'Оплата голосами недоступна на вашем устройстве',
        (
          <span>
            Воспользуйтесь веб-приложением, мобильным устройством на Android, либо зайдите на мобильную веб-версию через браузер по ссылке <a href='https://m.vk.com/vk_temperament'>https://m.vk.com/vk_temperament</a>.
          </span>
        ),
      );
    } else {
      predictionContentRef.current?.scrollIntoView();

      if (grantedTokenScope && grantedTokenScope.includes('friends')) {
        onGetToken();
      } else {
        setPopoutAlert(
          'Необходимы дополнительные разрешения',
          'Для дальнейшей работы приложения нужен доступ к Вашим друзьям. Без этого доступа узнать темперамент не получится',
          onGetToken,
          'Хорошо'
        );
      }
    }
  };
  const onUseFriendSearch = () => router.forward({ panel: PANEL.SEARCH_FRIEND });
  const onChooseUser = () => router.forward({ content: CONTENT_ITEMS.AFTER_USER_CHOOSE });
  const onAfterUserChoose = useBuyPredictionIfNeeded(
    async () => {
      await router.forward({ panel: PANEL.RESULT });
    }
  );

  return (
    <PredictionPanel
      needShowHeaderBack={needShowHeaderBack}
      handleBack={handleBack}
      showed={showed}
      handlePredictionTab={handlePredictionTab}
      goToFriendSearch={goToFriendSearch}
      handleAboutTab={handleAboutTab}
      displaies={displaies}
      onScrollAnimationComplete={onScrollAnimationComplete}
      onNextAboutClick={onNextAboutClick}
      showedAbout={showedAbout}
      showedStacked={showedStacked}
      predictionContentRef={predictionContentRef}
      afterUserChooseRef={afterUserChooseRef}
      onAfterUserChoose={onAfterUserChoose}
      handleOnGetTemperament={handleOnGetTemperament}
      friendTemperamentRef={friendTemperamentRef}
      handleOnGetFriendTemperament={handleOnGetFriendTemperament}
      onUseFriendSearch={onUseFriendSearch}
      onChooseUser={onChooseUser}
      {...props}
    />
  );
};

export default PredictionPanelContainer;

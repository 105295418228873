import { connect } from 'react-redux';

import { SuccessSnackbar } from './SuccessSnackbar';
import { AppDispatch, RootState } from '../../../store';
import { resetPopout } from '../../../store/popoutSlice';
import { selectPopoutMetaInfo } from '../../../store/popoutSlice/popoutSelectors';


function mapStateToProps(state: RootState) {
  return {
    ...(selectPopoutMetaInfo(state) as { message: string, duration: number }),
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    onSnackbarClose: () => dispatch(resetPopout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuccessSnackbar);

import { RouterStateType } from './types';
import { defaultLocation } from '../../constants/History';

export const routerInitialState: RouterStateType = {
  data: {
    handler: 'none',
    location: defaultLocation,
    operation: null,
  },
};

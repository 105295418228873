import api from './api';
import { EventType } from '../types/event.type';

function sendStatistics(event: EventType) {
  return api('events', event);
}

const eventsApi = { sendStatistics };

export default eventsApi;

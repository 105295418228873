import React from 'react';

import { Icon28HelpCircleOutline, Icon20ShoppingCartOutline } from '@vkontakte/icons';
import {
  Button,
  Group,
  Panel,
  PanelHeader,
  Spacing,
  Text,
  Title,
} from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './DesktopPredictionPanel.module.css';
import { DesktopPredictionPanelProps } from './types';
import { IconedText } from '../../../../../components/IconedText';
import { FRIEND_PREDICTION_PRICE } from '../../../../../constants/Common';
import { PersonSVG } from '../../../../../images/desktop/PersonSVG';

export const DesktopPredictionPanel: React.FC<DesktopPredictionPanelProps> = ({
  id,
  goToAboutUs,
  handleGetFriendTemperamentClick,
  handleGetMyTemperamentClick
}) => {
  return (
    <Panel id={id} className={styles.panel}>
      <PanelHeader>
          AI темперамент
      </PanelHeader>
      <Group className={styles.container} separator="hide">
        <AnimatePresence>
          <motion.div animate={{ scale: [1, 1.02, 1] }} transition={{ duration: 0.3 }}>
            <PersonSVG width="100%" height="auto" />
          </motion.div>
        </AnimatePresence>
        <Spacing size={40} />
        <div className={styles.informativeContent}>
          <div className={styles.optionContainer}>
            <div className={styles.upperBox}>
              <Title level="2" className={styles.title}>
                  Узнать свой темперамент
              </Title>
              <Spacing size={10} />
              <Text className={styles.text}>
                  Проверьте бесплатно с помощью искусственного интеллекта, что ваша страница говорит о Вас
              </Text>
              <Spacing size={45} />
            </div>
            <Button
              onClick={handleGetMyTemperamentClick}
              mode="primary"
              size="l"
            >
                Узнать темперамент
            </Button>
            <Spacing size={42} />
            <Button
              onClick={goToAboutUs}
              before={<Icon28HelpCircleOutline />}
              mode="tertiary"
              size="l"
            >
                О проекте
            </Button>
          </div>
          <div className={styles.separator} />
          <div className={styles.optionContainer}>
            <div className={styles.upperBox}>
              <Title level="2" className={styles.title}>
                  Узнать темперамент других
              </Title>
              <Spacing size={8}/>
              <Text className={styles.text}>
                  Оплатите голосами и узнайте, что страницы людей говорят об их темпераменте
              </Text>
              <Spacing size={45}/>
            </div>
            <Button
              onClick={handleGetFriendTemperamentClick}
              mode="primary"
              size="l"
            >
                Узнать темперамент
            </Button>
            <Spacing size={25}/>
            <div className={styles.iconedTextContainer}>
              <IconedText
                icon={
                  <AnimatePresence>
                    <motion.div animate={{ x: [6, -4, 0] }} transition={{ duration: 0.3 }}>
                      <Icon20ShoppingCartOutline className={styles.icon}/>
                    </motion.div>
                  </AnimatePresence>
                }
                text={(
                  <span className={styles.iconedText}>
                    Проверка другого человека становится доступна после оплаты {FRIEND_PREDICTION_PRICE} голоса. В случае ошибки оплата голосов не будет произведена
                  </span>
                )}
              />
            </div>
          </div>
        </div>
      </Group>
    </Panel>
  );
};

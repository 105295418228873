import React from 'react';

import { DesktopAboutUs } from './DesktopAboutUs';
import { DesktopAboutUsContainerProps } from './types';
import { PANEL } from '../../../../constants/Panel';
import { useRouter } from '../../../../hooks/useRouter';

const DesktopAboutUsContainer: React.FC<DesktopAboutUsContainerProps> = (props) => {
  const router = useRouter();
  const goToPrediction = () => router.forward({ panel: PANEL.PREDICTION });

  return (
    <DesktopAboutUs
      onBackClick={router.back}
      goToPrediction={goToPrediction}
      {...props}
    />
  );
};

export default DesktopAboutUsContainer;

import { CONTENT_ITEMS, ContentItemsType } from './Content';
import { ModalType } from './Modal';
import { PANEL, PanelType } from './Panel';
import { VIEW, ViewType } from './View';

export const historyOperation = {
  PUSH: 'push',
  POP: 'pop',
  REPLACE: 'replace'
} as const;

export type HistoryOperationType = typeof historyOperation[keyof typeof historyOperation];

export type LocationType = {
  view: ViewType;
  panel: PanelType;
  modal: ModalType | null;
  content: ContentItemsType;
  // content: {
  //   tab: 'prediction' | 'about'
  //   prediction: ContentItemsType,
  //   about: ContentItemsType,
  // };
  length: number;
};

export const defaultLocation: LocationType = {
  view: VIEW.PREDICTION,
  panel: PANEL.PREDICTION,
  modal: null,
  content: CONTENT_ITEMS.PREDICTION,
  // content: {
  //   tab: 'prediction',
  //   prediction: CONTENT_ITEMS.PREDICTION,
  //   about: CONTENT_ITEMS.ABOUT_US_1,
  // },
  length: 1
};

// export const defaultLocation: LocationType = {
//   view: VIEW.PREDICTION,
//   panel: PANEL.PREDICTION,
//   modal: null,
//   content: {
//     tab: 'prediction',
//     prediction: CONTENT_ITEMS.PREDICTION,
//     about: CONTENT_ITEMS.ABOUT_US_1,
//   },
//   length: 1
// };

import React from 'react';

import {
  Avatar,
  Group,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Search,
  Separator,
  SimpleCell,
  Spacing,
  Spinner,
  Title,
  Text
} from '@vkontakte/vkui';

import styles from './SearchFriendPanel.module.css';
import { SearchFriendPanelPropsType } from './types';
import { HeaderContent } from '../../../../components/HeaderContent';

export const SearchFriendPanel: React.FC<SearchFriendPanelPropsType> = ({
  id,
  onBackClick,
  isDesktop,
  loading,
  usersToShow,
  searchText,
  onFriendClick,
  onSearchTextChange,
}) => {
  return (
    <Panel id={id}>
      <PanelHeader
        separator={isDesktop}
        before={
          isDesktop ? (
            <PanelHeaderBack onClick={onBackClick} />
          ) : (
            <HeaderContent needBackIcon title="Поиск" onClick={onBackClick} />
          )
        }
      >
        {isDesktop && 'Поиск'}
      </PanelHeader>
      <Group className={isDesktop ? styles.containerDesktop : styles.containerMobile}>
        <Search
          value={searchText}
          onChange={onSearchTextChange}
          // TODO: Добавить позже
          // after={
          //   <Icon24Filter
          //     onClick={() => {
          //       router.forward({ modal: MODAL.MODAL_SEARCH });
          //     }}
          //   />
          // }
        />
        <Spacing size={isDesktop ? 12 : 16} />
        <Separator />
        {
          (loading || !usersToShow || usersToShow.length !== 0 ) && (
            <Title level="2" className={styles.title}>
            Выбрать среди друзей:
            </Title>
          )
        }
        {
          searchText && usersToShow && usersToShow.length === 0 && (
            <Text className={styles.text}>
              Друзья не найдены 😞
            </Text>
          )
        }
        {
          !loading && usersToShow && usersToShow.length !== 0 && (
            <div>
              {
                usersToShow.map(({ photoSrc, subtitle, name, vkId }: any) => (
                  <SimpleCell
                    key={id}
                    before={<Avatar size={48} src={photoSrc} />}
                    subtitle={subtitle}
                    onClick={() => onFriendClick(vkId)}
                  >
                    {name}
                  </SimpleCell>
                ))
              }
            </div>
          )
        }
        {
          loading && <Spinner className={styles.spinner}/>
        }
      </Group>
    </Panel>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';


export const selectPopout = (state: RootState) => state.popout;

export const selectPopoutType = createSelector(
  selectPopout,
  (popout) => popout.type,
);

export const selectPopoutMetaInfo = createSelector(
  selectPopout,
  (popout) => popout.meta_info,
);

import { connect } from 'react-redux';

import { PredictionLoading } from './PredictionLoading';
import { RootState } from '../../../../../../store';
import { selectIsDesktop } from '../../../../../../store/settingsSlice/settingsSelectors';

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(PredictionLoading);

import React from 'react';

import { useSelector } from 'react-redux';

import { DesktopAfterUserChoose } from './DesktopAfterUserChoose';
import { DesktopAfterUserChooseContainerProps } from './types';
import { PANEL } from '../../../../constants/Panel';
import { useBuyPredictionIfNeeded } from '../../../../hooks/useBuyPredictionIfNeeded';
import { useRouter } from '../../../../hooks/useRouter';
import { selectSelectedUserData } from '../../../../store/FriendSearchSlice/popoutSelectors';

const DesktopAfterUserChooseContainer: React.FC<DesktopAfterUserChooseContainerProps> = (props) => {
  const router = useRouter();
  const buyPredictionIfNeeded = useBuyPredictionIfNeeded(
    async () => {
      await router.forward({ panel: PANEL.RESULT });
    }
  );
  const user = useSelector(selectSelectedUserData);

  return (
    <DesktopAfterUserChoose
      friend={user}
      onBackClick={router.back}
      goToResult={buyPredictionIfNeeded}
      {...props}
    />
  );
};

export default DesktopAfterUserChooseContainer;

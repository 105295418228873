import React, { forwardRef, useState } from 'react';

import { Icon28SearchStarsOutline } from '@vkontakte/icons';
import {
  Button,
  FormItem,
  FormLayout,
  Input,
  Spacing,
  Text,
  Title,
} from '@vkontakte/vkui';
import { useSelector } from 'react-redux';

import { UserEnteringProps } from './types';
import eventsApi from '../../../../../../api/EventsApi';
import { getOutUserData } from '../../../../../../api/VKWebApi';
import { EVENT_NAME, EVENT_TYPE } from '../../../../../../constants/Event';
import { POPOUT } from '../../../../../../constants/Popout';
import { useGetFriends } from '../../../../../../hooks/useGetFriends';
import { usePopout, useSetPopoutAlert } from '../../../../../../hooks/usePopout';
import { useSetAccessToken } from '../../../../../../hooks/useSetAccessToken';
import { useSetSelectedFriend } from '../../../../../../hooks/useSetSelectedFriend';
import { selectLocation } from '../../../../../../store/routerSlice/routerSelectors';
import { selectUserAccessToken } from '../../../../../../store/userSlice/userSelectors';
import { handleErrorToStore } from '../../../../../../utils/handleErrorToStore';
import { PaymentLayout } from '../PaymentLayout';


export const UserEntering = forwardRef<HTMLDivElement, UserEnteringProps>(({
  delayAnimation,
  onUseFriendSearch,
  onChooseUser,
}, ref) => {
  const accessToken = useSelector(selectUserAccessToken);
  const setAccessToken = useSetAccessToken();
  const location = useSelector(selectLocation);
  const [textInput, setTextInput] = useState<string>();
  const [error, setError] = useState<string>('');
  const getFriends = useGetFriends();
  const setSelectedFriend = useSetSelectedFriend();
  const setPopoutAlert = useSetPopoutAlert();
  const { setPopout, resetPopout } = usePopout();

  const onTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setTextInput(e.currentTarget.value);

  const handlePayButtonClick = async () => {
    if (textInput) {
      let users;

      setPopout(POPOUT.SMART_SPINNER);
      let actualAccessToken = accessToken ? accessToken : await setAccessToken(['friends']);
      if (actualAccessToken) {
        users = await getFriends(actualAccessToken);
        resetPopout();
      } else {
        setPopoutAlert(
          'Ошибка загрузки данных',
          'При загрузке Ваших друзей произошла ошибка. Попробуйте нажать кнопку "Оплатить и узнать результаты" еще раз'
        );
        return;
      }

      if (!users) {
        setError('Данные о друзьях не загрузились, попробуйте чуть позже');
        return;
      }

      const parsedTextInput =  textInput
        .replace('https','')
        .replace('http','')
        .replace('://','')
        .replace('/id','')
        .replace('vk.com','')
        .replace('/','');

      for (const friend of users) {
        if (Boolean(parsedTextInput.trim()) &&
            (friend.domain.toString().includes(parsedTextInput) || friend.vkId.toString().includes(parsedTextInput))
        ) {
          await setSelectedFriend(friend.vkId);
          onChooseUser();
          return;
        }
      }

      if (accessToken) {
        let id;
        let is_closed;
        try {
          const response = await getOutUserData(parsedTextInput, accessToken);
          id = response.id;
          is_closed = response.is_closed;
        } catch (error) {
          await eventsApi.sendStatistics({
            type: EVENT_TYPE.VK_API,
            name: EVENT_NAME.USERS_GET_VK_API_ERROR,
            data: {
              message: 'Ошибка при определении того, открыта ли страница пользователя (handlePayButtonClick)',
              error: handleErrorToStore(error),
            },
            route: location,
          });
        }

        if (is_closed) {
          setError('Страница недоступна или заблокирована');
          return;
        } else if (typeof id !== 'undefined') {
          await setSelectedFriend(id);
          onChooseUser();
          return;
        } else {
          setError('Ошибка доступа к странице');
        }
      }

      setError('По этой ссылке пользователь не был найден');
    } else {
      setError('Введите ссылку');
    }
  };

  return (
    <PaymentLayout delayAnimation={delayAnimation} ref={ref}>
      <Title level="2" weight="2" style={{ textAlign: 'center', margin: '0 32px' }}>
              Узнать темперамент другого пользователя
      </Title>

      <Spacing size={16} />

      <FormLayout>
        <FormItem
          top="Ссылка на страницу пользователя"
          status={error ? 'error' : 'default'}
          bottom={ error }
        >
          <Input
            value={textInput}
            type="text"
            placeholder="Введите ссылку"
            onChange={onTextInputChange}
          />
        </FormItem>
      </FormLayout>

      <Spacing size={8} />

      <div style={{ margin: '0 16px' }}>
        <Button mode="tertiary" size="m" before={<Icon28SearchStarsOutline />} stretched onClick={onUseFriendSearch}>
                  Темперамент друга
        </Button>

        <Spacing size={12} />

        <Text style={{
          fontSize: 14,
          color: 'var(--light-text-secondary, #818C99)',
          textAlign: 'center',
          margin: '0 16px',
        }}>
                  Оплатите голосами и узнайте, что страницы людей говорят об их темпераменте
        </Text>

        <Spacing size={12} />

        <Button size="l" mode="primary" stretched onClick={handlePayButtonClick}>
                  Оплатить и узнать результаты
        </Button>

        <Spacing size={24} />
      </div>
    </PaymentLayout>
  );
});

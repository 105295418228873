import bridge from '@vkontakte/vk-bridge';

import {
  getAge, getHasArrayProperty,
  getHasMobilePhone,
  getHasProperty,
  getNumberProperty,
  getSex
} from './parseUtils';

export const getUsersCommonData = async (
  usersIds: number[],
  accessToken: string,
)=> {
  const { response } = await bridge.send('VKWebAppCallAPIMethod', {
    method: 'users.get',
    request_id: 'tics42',
    params: {
      v: '5.131',
      user_ids: usersIds.join(),
      access_token: accessToken,
      fields: 'first_name,last_name,career,city,photo_50,domain,can_access_closed,deactivated'
    },
  });

  const users = response.map(({
    id: vkId,
    career,
    first_name,
    last_name,
    city,
    photo_50: photoSrc,
    domain,
    can_access_closed,
    deactivated,
  }: any) => {
    let subtitle;
    if (career && career.length > 0 && career[career.length - 1].company) {
      subtitle = career[career.length - 1].company;
    } else if (city && 'title' in city && city.title) {
      subtitle = city.title;
    }
    const name = `${first_name} ${last_name}`;
    const isClosed = !Boolean(can_access_closed) || deactivated === 'deleted' || deactivated === 'banned';

    return { name, subtitle, photoSrc, vkId, domain, isClosed };
  });
  return users;
};

export const getFriends = async (
  accessToken: string,
) => {
  const { response: { items } } = await bridge.send('VKWebAppCallAPIMethod', {
    method: 'friends.get',
    request_id: 'tics42',
    params: {
      v: '5.131',
      access_token: accessToken,
      order: 'name'
    },
  });

  return await getUsersCommonData(items, accessToken);
};

export const getOutUserData = async (
  userId: string | number,
  accessToken: string,
) => {
  const { response } = await bridge.send('VKWebAppCallAPIMethod', {
    method: 'users.get',
    request_id: 'tics42',
    params: {
      v: '5.131',
      user_ids: userId.toString(),
      access_token: accessToken,
      fields: 'id,can_access_closed,deactivated'
    },
  });

  if (!response || response.length === 0) {
    return { id: undefined, is_closed: undefined };
  }

  const user = response[0];
  const id = user['id'];
  const is_closed = !Boolean(user['can_access_closed']) || user['deactivated'] === 'deleted' || user['deactivated'] === 'banned';

  return { id, is_closed };
};

export const collectInfo = async (
  vkId: number,
  accessToken: string,
) => {
  const props = {
    method: 'users.get',
    request_id: 'tics42',
    params: {
      v: '5.131',
      user_ids: vkId.toString(),
      access_token: accessToken,
      fields: 'bdate,has_mobile,site,status,followers_count,sex,counters,city,career,home_town,relation,schools,relatives,contacts'
    },
  };
  const { response } = await bridge.send('VKWebAppCallAPIMethod', props);

  const dirtyUserData = response[0];

  const id = dirtyUserData['id'];
  if (id.toString() !== vkId.toString()) {
    throw Error('User ids do not match');
  }

  const cleanUserData: any = {};

  cleanUserData['age'] = getAge(dirtyUserData);
  cleanUserData['has_mobile_phone'] = getHasMobilePhone(dirtyUserData) || getHasProperty(dirtyUserData, 'has_mobile');
  cleanUserData['has_site'] = getHasProperty(dirtyUserData, 'site');
  cleanUserData['has_status'] = getHasProperty(dirtyUserData, 'status');
  cleanUserData['followers_count'] = getNumberProperty(dirtyUserData, 'followers_count');
  cleanUserData['sex'] = getSex(dirtyUserData);
  cleanUserData['has_city'] = getHasProperty(dirtyUserData, 'city');
  cleanUserData['has_career'] = getHasProperty(dirtyUserData, 'career');
  cleanUserData['has_home_town'] = getHasProperty(dirtyUserData, 'home_town');
  cleanUserData['relation'] = getNumberProperty(dirtyUserData, 'relation');
  cleanUserData['has_schools'] = getHasArrayProperty(dirtyUserData, 'schools');
  cleanUserData['has_relatives'] = getHasArrayProperty(dirtyUserData, 'relatives');

  if (getHasProperty(dirtyUserData, 'counters')) {
    const counters = dirtyUserData['counters'];
    const properties = [
      'albums', 'followers', 'friends', 'pages', 'photos',
      'subscriptions', 'user_photos', 'gifts', 'groups'
    ];
    for (const property of properties) {
      cleanUserData[property] = getNumberProperty(counters, property);
    }
  }

  return cleanUserData;
};

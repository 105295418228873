import React from 'react';

import { ScreenSpinner } from '@vkontakte/vkui';

export const SmartSpinner: React.FC = () => {
  return (
    <ScreenSpinner
      size='large'
      state='loading'
    />
  );
};

import { PANEL } from './Panel';
import { VIEW } from './View';


export const STRUCTURE = {
  DEFAULT_VIEW: VIEW.PREDICTION,
  VIEWS: {
    [VIEW.PREDICTION]: {
      DEFAULT_PANEL: PANEL.PREDICTION,
      PANELS: [
        PANEL.PREDICTION,
        PANEL.SEARCH_FRIEND,
        PANEL.USER_ENTERING,
        PANEL.RESULT,
      ],
      DEFAULT_MODAL: null,
      MODALS: [],
    },
    [VIEW.ERROR]: {
      DEFAULT_PANEL: PANEL.ERROR,
      PANELS: [
        PANEL.ERROR,
      ],
      DEFAULT_MODAL: null,
      MODALS: [],
    },
  },
};

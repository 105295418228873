import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';


export const selectFriendSearch = (state: RootState) => state.friendSearch;

export const selectSex = createSelector(
  selectFriendSearch,
  (friendSearch) => friendSearch.sex,
);


export const selectFriends = createSelector(
  selectFriendSearch,
  (friendSearchData) => friendSearchData.friends
);

export const selectSelectedUserData = createSelector(
  selectFriendSearch,
  (friendSearchData) => ({
    ...friendSearchData.selectedUserData,
    isSelectedUserPurchased: friendSearchData.isSelectedUserPurchased
  })
);

import React from 'react';

import { Spacing, Text, Title } from '@vkontakte/vkui';

import styles from './TextContent.module.css';
import { TextContentProps } from './types';

export const TextContent: React.FC<TextContentProps> = ({ title, text }) => {
  return (
    <div>
      <Title level="2" className={styles.title}>
        {title}
      </Title>
      <Spacing size={1} />
      <Text className={styles.text}>
        {text}
      </Text>
    </div>
  );
};

import React from 'react';

import {
  Button,
  Text,
  ButtonGroup,
  Alert,
  Title,
} from '@vkontakte/vkui';

import styles from './SharingPopout.module.css';
import { SharingPopoutProps } from './SharingPopout.types';

export const SharingPopout: React.FC<SharingPopoutProps> = ({
  shareOnWall,
  shareStory,
  onClose,
}) => {
  const Content = (
    <div className={styles.content}>
      <div className={styles.textContainer}>
        <Text weight='3' className={styles.text}>
          Адаптировать результаты не нужно — мы уже собрали их в пост для вас!
        </Text>
      </div>
      <ButtonGroup mode="vertical" align="center" stretched>
        <Button
          onClick={shareOnWall}
          size='m'
          mode="primary"
        >
          Поделиться на стене
        </Button>
        <Button
          onClick={shareStory}
          size='m'
          mode="secondary"
        >
          Поделиться в сторис
        </Button>
      </ButtonGroup>
    </div>
  );

  return (
    <Alert
      actionsLayout="horizontal"
      onClose={onClose}
      header={(
        <Title weight='2' level='2' className={styles.header}>
          Поделиться результатом
        </Title>
      )}
      text={Content}
    />
  );
};

import { createSlice } from '@reduxjs/toolkit';

import { routerInitialState } from './constants';
import { historyOperation } from '../../constants/History';


const routerSlice = createSlice({
  name: 'router',
  initialState: routerInitialState,
  reducers: {
    // Кидаем свойство isDesktop для определения CONTENT_ITEMS
    updateLocation(state, action) {
      const previousLength = state.data.location.length;
      const currentLength = action.payload.length;

      if (previousLength < currentLength) {
        state.data.operation = historyOperation.PUSH;
      }
      else if (previousLength === currentLength) {
        state.data.operation = historyOperation.REPLACE;
      }
      else {
        state.data.operation = historyOperation.POP;
      }

      if (action.payload.hasOwnProperty('location')) {
        Object.assign(state.data.location, action.payload.location);
        state.data.handler = action.payload.handler;
      } else {
        Object.assign(state.data.location, action.payload);
      }
    },
  },
});

export const { updateLocation } = routerSlice.actions;

export default routerSlice.reducer;

import React, { useState } from 'react';

import {
  FormItem,
  Group,
  ModalPage,
  ModalPageHeader,
  PanelHeaderClose,
  PanelHeaderSubmit, Radio, RadioGroup,
  useAdaptivityConditionalRender
} from '@vkontakte/vkui';
import { useSelector } from 'react-redux';

import { SearchModalProps } from './types';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useRouter } from '../../../hooks/useRouter';
import { updateFriendSearchData } from '../../../store/FriendSearchSlice';
import { selectSex } from '../../../store/FriendSearchSlice/popoutSelectors';


export const SearchModal: React.FC<SearchModalProps> = ({ id }) => {
  const dispatch = useAppDispatch();

  const { sizeX } = useAdaptivityConditionalRender();
  const sexInitial = useSelector(selectSex);

  const [sex, setSex] = useState<'male' | 'female' | undefined>(sexInitial);

  const router = useRouter();

  const modalBack = () => router.back();

  const handleSexChange = (e: any) => {
    const newValue = e.target.value;
    setSex(newValue !== 'none' ? newValue : undefined);
  };

  return (
    <ModalPage
      id={id}
      onClose={modalBack}
      header={
        <ModalPageHeader
          before={
            sizeX.compact && (
              <PanelHeaderClose className={sizeX.compact.className} onClick={modalBack} />
            )
          }
          after={<PanelHeaderSubmit onClick={() => {
            dispatch(updateFriendSearchData({ sex }));
            modalBack();
          }} />}
        >
          Фильтры
        </ModalPageHeader>
      }
    >
      <Group>
        <FormItem top="Пол">
          <RadioGroup>
            {
              [['male', 'Мужской'], ['female', 'Женский'], ['none', 'Любой']].map(([value, text]) => (
                <Radio
                  key={value}
                  name="sex"
                  value={value}
                  defaultChecked={(value === sexInitial) || (!sexInitial && value === 'none') }
                  onChange={handleSexChange}
                >
                  {text}
                </Radio>
              ))
            }
          </RadioGroup>
        </FormItem>
      </Group>
    </ModalPage>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { popoutInitialState } from './constants';
import { PopoutStateType } from './types';
import { PopoutType } from '../../constants/Popout';


const popoutSlice = createSlice({
  name: 'popout',
  initialState: popoutInitialState,
  reducers: {
    setPopout(state, action: PayloadAction<PopoutStateType>) {
      Object.assign(state, action.payload);
    },
    resetPopout(state) {
      Object.assign(state, popoutInitialState);
    },
    setPopoutType(state, action: PayloadAction<PopoutType>) {
      state.type = action.payload;
    },
    resetPopoutType(state) {
      state.type = null;
    },
  },
});

export const {
  setPopout,
  resetPopout,
  setPopoutType,
  resetPopoutType,
} = popoutSlice.actions;

export default popoutSlice.reducer;

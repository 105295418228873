import { LaunchParamsType } from '../constants/LaunchParams';

const STRING_CONST = 'string';
const NUMBER_CONST = 'number';

const launchKeys: Record<string, 'string' | 'number'> = {
  sign: STRING_CONST,
  vk_access_token_settings: STRING_CONST,
  vk_app_id: NUMBER_CONST,
  vk_are_notifications_enabled: NUMBER_CONST,
  vk_is_app_user: NUMBER_CONST,
  vk_is_favorite: NUMBER_CONST,
  vk_language: STRING_CONST,
  vk_platform: STRING_CONST,
  vk_ref: STRING_CONST,
  vk_ts: NUMBER_CONST,
  vk_user_id: NUMBER_CONST,
} as const;

export const getLaunchParams = () => {
  const launchParams: any = {};

  const search = window.location.search.startsWith('?')
    ? window.location.search.slice(1)
    : window.location.search;

  const allParams = search.split('&');

  for (const param of allParams) {
    const [key, value] = param.split('=');

    if (key in launchKeys) {
      launchParams[key] = launchKeys[key] === NUMBER_CONST ? Number(value) : value;
    }
  }

  return launchParams as LaunchParamsType;
};

import React from 'react';

import {
  Panel,
  PanelHeader,
  Tabs,
  TabsItem
} from '@vkontakte/vkui';

import { MAIN_HORIZONTAL_SCROLL_DURATION } from './constants';
import { PredictionContent } from './content/PredictionContent';
import { PredictionPanelProps } from './types';
import { HeaderContent } from '../../../../../../components/HeaderContent';
import { HorizontalScroll } from '../../../../../../components/HorizontalScroll';
import { StackedScreens } from '../../../../../../components/StackedScreens';
import { MOBILE_INTRO_PANELS_OPTIONS } from '../../../../../../constants/AboutData';
import { CholericSVG } from '../../../../../../images/mobile/CholericSVG';
import { PersonSVG } from '../../../../../../images/mobile/PersonSVG';
import { PhlegmaticSVG } from '../../../../../../images/mobile/PhlegmaticSVG';
import { SanguineSVG } from '../../../../../../images/mobile/SanguineSVG';
import { AfterUserChoose } from '../AfterUserChoose';
import { MobileIntroContent } from '../MobileIntroContent';
import { UserEntering } from '../UserEntering';
import '../forTestMVkCom.css';

export const PredictionPanel: React.FC<PredictionPanelProps> = ({
  id,
  goToFriendSearch,
  needShowHeaderBack,
  handleBack,
  showed,
  handlePredictionTab,
  handleAboutTab,
  displaies,
  onScrollAnimationComplete,
  onNextAboutClick,
  showedAbout,
  showedStacked,
  predictionContentRef,
  afterUserChooseRef,
  onAfterUserChoose,
  handleOnGetTemperament,
  friendTemperamentRef,
  handleOnGetFriendTemperament,
  onUseFriendSearch,
  onChooseUser
}) => {
  const blockWidth = window.innerWidth;

  return (
    <Panel id={id} style={{ display: 'flex', height: '100%', width: '100%' }}>
      <CholericSVG width={0} height={0}/>
      <PhlegmaticSVG width={0} height={0}/>
      <SanguineSVG width={0} height={0}/>

      <PanelHeader separator={false} before={needShowHeaderBack ? <HeaderContent needBackIcon title="Назад" onClick={handleBack}/>: undefined} />
      <Tabs>
        <TabsItem
          selected={showed.index === 0}
          onClick={handlePredictionTab}
        >
          Тест
        </TabsItem>
        <TabsItem
          selected={showed.index === 1}
          onClick={handleAboutTab}
        >
          О проекте
        </TabsItem>
      </Tabs>

      <PersonSVG width={0} height={0} />

      <HorizontalScroll
        style={{
          display: 'flex',
          height: '100%',
        }}
        duration={MAIN_HORIZONTAL_SCROLL_DURATION}
        showedData={showed}
        offsetWidth={blockWidth}
        vignetteWidth={12}
        onAnimationComplete={onScrollAnimationComplete}
      >
        {[0, 1].map((option, index) => {
          const component = option === 1 ? (
            <MobileIntroContent
              key='MobileIntroContent'
              delayAnimation={MAIN_HORIZONTAL_SCROLL_DURATION}
              onNextAboutClick={onNextAboutClick}
              panelOptions={MOBILE_INTRO_PANELS_OPTIONS}
              showed={showedAbout}
            />
          ) : (
            <div
              key="StackedScreens"
              style={{
                position: 'relative',
                display: 'flex',
                width: blockWidth,
                flexShrink: 0,
                height: '100%',
              }}>
              <StackedScreens
                showedData={showedStacked}
                width={blockWidth}
                screens={[
                  <PredictionContent
                    ref={predictionContentRef}
                    delayAnimation={MAIN_HORIZONTAL_SCROLL_DURATION}
                    handleOnGetTemperament={handleOnGetTemperament}
                    handleOnGetFriendTemperament={handleOnGetFriendTemperament}
                  />,
                  <UserEntering
                    ref={friendTemperamentRef}
                    delayAnimation={MAIN_HORIZONTAL_SCROLL_DURATION}
                    onUseFriendSearch={onUseFriendSearch}
                    onChooseUser={onChooseUser}
                  />,
                  <AfterUserChoose
                    ref={afterUserChooseRef}
                    delayAnimation={MAIN_HORIZONTAL_SCROLL_DURATION}
                    onAfterUserChoose={onAfterUserChoose}
                  />
                ]}
                screenIds={['PredictionContent', 'FriendTemperament', 'AfterUserChoose']}
              />
            </div>
          );

          return displaies[index] === 'none' ? (
            <div
              key={'none'}
              style={{
                width: blockWidth,
                height: 0,
                flexShrink: 0,
              }}
            />
          ) : component;
        })}
      </HorizontalScroll>

    </Panel>
  );
};


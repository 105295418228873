import React from 'react';

import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import { HorizontalScrollProps } from './types';
import { selectAppearance } from '../../store/settingsSlice/settingsSelectors';

export const HorizontalScroll: React.FC<HorizontalScrollProps> = ({
  style,
  showedData,
  offsetWidth,
  vignetteWidth,
  children,
  onAnimationComplete,
  duration = 0.3,
  delay = 0,
}) => {
  const appearance = useSelector(selectAppearance);
  const gradient = appearance === 'light' ? (
    `linear-gradient(to right, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) ${vignetteWidth}px, rgba(255,255,255,0) ${offsetWidth - vignetteWidth}px, rgba(255,255,255,1) 100%)`
  ) : (
    `linear-gradient(to right, rgba(25,25,26,0.9) 0%, rgba(25,25,26,0) ${vignetteWidth}px, rgba(25,25,26,0) ${offsetWidth - vignetteWidth}px, rgba(25,25,26,1) 100%)`
  );

  return (
    <div style={{
      overflowX: 'hidden',
      position: 'relative',
      width: offsetWidth,
      ...style,
    }}>
      <div
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          backgroundImage: gradient,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 999
        }}
      />
      <motion.div
        initial={false}
        animate={{ x: showedData.index * -offsetWidth }}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row'
        }}
        onAnimationComplete={onAnimationComplete}
        transition={{
          duration,
          ease: 'easeInOut',
          delay
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

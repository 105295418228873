import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { DesktopUserEntering } from './DesktopUserEntering';
import { DesktopUserEnteringContainerProps } from './types';
import eventsApi from '../../../../api/EventsApi';
import { getOutUserData } from '../../../../api/VKWebApi';
import { EVENT_NAME, EVENT_TYPE } from '../../../../constants/Event';
import { PANEL } from '../../../../constants/Panel';
import { POPOUT } from '../../../../constants/Popout';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useGetFriends } from '../../../../hooks/useGetFriends';
import { usePopout, useSetPopoutAlert } from '../../../../hooks/usePopout';
import { useRouter } from '../../../../hooks/useRouter';
import { useSetAccessToken } from '../../../../hooks/useSetAccessToken';
import { useSetSelectedFriend } from '../../../../hooks/useSetSelectedFriend';
import { clearSelectedUser } from '../../../../store/FriendSearchSlice';
import { selectLocation } from '../../../../store/routerSlice/routerSelectors';
import { selectUserAccessToken } from '../../../../store/userSlice/userSelectors';
import { handleErrorToStore } from '../../../../utils/handleErrorToStore';


const  DesktopUserEnteringContainer: React.FC<DesktopUserEnteringContainerProps> = (props) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const accessToken = useSelector(selectUserAccessToken);

  useEffect(() => {
    dispatch(clearSelectedUser());
  }, [dispatch]);

  const [textInput, setTextInput] = useState<string>();
  const [error, setError] = useState<string>('');
  const getFriends = useGetFriends();
  const setSelectedFriend = useSetSelectedFriend();
  const { setPopout, resetPopout } = usePopout();
  const setPopoutAlert = useSetPopoutAlert();
  const setAccessToken = useSetAccessToken();
  const location = useSelector(selectLocation);

  const onTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setTextInput(e.currentTarget.value);

  const handlePayButtonClick = async () => {
    if (textInput) {
      let users;

      setPopout(POPOUT.SMART_SPINNER);
      let actualAccessToken = accessToken ? accessToken : await setAccessToken(['friends']);
      if (actualAccessToken) {
        users = await getFriends(actualAccessToken);
        resetPopout();
      } else {
        setPopoutAlert(
          'Ошибка загрузки данных',
          'При загрузке Ваших друзей произошла ошибка. Попробуйте нажать кнопку "Оплатить и узнать результаты" еще раз'
        );
        return;
      }

      if (!users) {
        setError('Данные о друзьях не загрузились, попробуйте чуть позже');
        return;
      }

      const parsedTextInput =  textInput
        .replace('https','')
        .replace('http','')
        .replace('://','')
        .replace('/id','')
        .replace('vk.com','')
        .replace('/','');

      for (const friend of users) {
        if (Boolean(parsedTextInput.trim()) &&
            (friend.domain.toString().includes(parsedTextInput) || friend.vkId.toString().includes(parsedTextInput))
        ) {
          await setSelectedFriend(friend.vkId);
          router.forward({ panel: PANEL.AFTER_USER_CHOOSE });
          return;
        }
      }

      if (accessToken) {
        let id;
        let is_closed;
        try {
          const response = await getOutUserData(parsedTextInput, accessToken);
          id = response.id;
          is_closed = response.is_closed;
        } catch (error) {
          await eventsApi.sendStatistics({
            type: EVENT_TYPE.VK_API,
            name: EVENT_NAME.USERS_GET_VK_API_ERROR,
            data: {
              message: 'Ошибка при определении того, открыта ли страница пользователя (handlePayButtonClick)',
              error: handleErrorToStore(error),
            },
            route: location,
          });
        }

        if (is_closed) {
          setError('Страница недоступна или заблокирована');
          return;
        } else if (typeof id !== 'undefined') {
          await setSelectedFriend(id);
          router.forward({ panel: PANEL.AFTER_USER_CHOOSE });
          return;
        } else {
          setError('Ошибка доступа к странице');
        }
      }

      setError('По этой ссылке пользователь не был найден');
    } else {
      setError('Введите ссылку');
    }
  };

  const handleUserSearchButton = () => {
    router.forward({ panel: PANEL.SEARCH_FRIEND });
  };

  return (
    <DesktopUserEntering
      onBackClick={router.back}
      handlePayButtonClick={handlePayButtonClick}
      handleUserSearchButton={handleUserSearchButton}
      onTextInputChange={onTextInputChange}
      error={error}
      textInput={textInput}
      {...props}
    />
  );
};

export default  DesktopUserEnteringContainer;

import React, { useEffect, useRef, useState } from 'react';

import {
  Group,
  Text,
  Title,
  Spacing,
  Spinner
} from '@vkontakte/vkui';

import styles from './PredictionLoading.module.css';
import { PredictionLoadingPropsType } from './types';
import { CatSVG as CatSVGDesktop } from '../../../../../../images/desktop/CatSVG';
import { CatSVG as CatSVGMobile } from '../../../../../../images/mobile/CatSVG';

export const PredictionLoading: React.FC<PredictionLoadingPropsType> = ({ isDesktop }) => {
  const [width, setWidth] = useState<any>(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && 'clientWidth' in ref.current) {
      setWidth(ref.current['clientWidth']);
    }
  }, []);

  return isDesktop ? (
    <Group className={styles.container}>
      <CatSVGDesktop width={0} height={0}/>
      <div ref={ref} className={styles.innerContainer}>
        <div>
          <Title level="2" weight="2" className={styles.title}>
              Искусственный интеллект уже анализирует страницу!
          </Title>
          <Spacing size={8} />
          <Text className={styles.text}>
              Предсказание обычно занимает несколько секунд
          </Text>
        </div>
        <Spinner size="medium" className={styles.spinner} />
        <CatSVGDesktop width={width} height={width * 246 / 661} />
      </div>
    </Group>
  ) : (
    <Group style={{ display: 'flex', height: '100%', padding: 0, width: '100%' }}>

      <CatSVGMobile width={0} height={0}/>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        flexDirection: 'column', height: '100%', width: '100%' }}>

        <div style={{ margin: '12px 32px 0px 32px' }}>
          <Title level="2" weight="2" style={{ textAlign: 'center', marginBottom: '8px' }}>
              Искусственный интеллект уже анализирует страницу!
          </Title>

          <Spacing size={8} />

          <Text style={{
            fontSize: 16,
            color: 'var(--light-text-secondary, #818C99)',
            textAlign: 'center'
          }}>
              Предсказание обычно занимает несколько секунд
          </Text>
        </div>

        <Spinner size="medium" style={{ width: 40, height: 40 }} />

        <CatSVGMobile width={window.innerWidth} height={window.innerWidth * 361 / 360}/>
      </div>
    </Group>
  );
};

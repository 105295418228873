import React, { forwardRef } from 'react';

import { Group, Spacing } from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import { PredictionContentProps } from './types';
import { PersonSVG } from '../../../../../../../../images/mobile/PersonSVG';
import { ContentCard } from '../ContentCard';

export const PredictionContent = forwardRef<HTMLDivElement, PredictionContentProps>(({
  handleOnGetTemperament,
  handleOnGetFriendTemperament,
  delayAnimation = 0,
}, ref) => {
  return (
    <div ref={ref}>
      <Group>

        <div style={{ margin: '16px 32px 0' }} >

          <ContentCard
            title="Узнать свой темперамент"
            text="Проверьте бесплатно с помощью искусственного интеллекта, что Ваша страница говорит о Вас"
            buttonText="Узнать темперамент"
            onClick={handleOnGetTemperament}
          />

          <Spacing size={30}/>
          <div style={{ margin: '0 18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AnimatePresence>
              <motion.div
                animate={{ scale: [0.7, 1.06, 1] }}
                transition={{
                  duration: 0.6,
                  delay: delayAnimation,
                }}
              >
                <PersonSVG width="100%" height="auto" />
              </motion.div>
            </AnimatePresence>
          </div>
          <Spacing size={30}/>

          <ContentCard
            title="Узнать темперамент других"
            text="Оплатите голосами и узнайте, что страницы людей говорят об их темпераменте"
            buttonText="Узнать темперамент"
            onClick={handleOnGetFriendTemperament}
          />

          <Spacing size={30}/>
        </div>
      </Group>
    </div>
  );
});

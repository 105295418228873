import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { settingsInitialState } from './constants';


const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsInitialState,
  reducers: {
    updateSettings(state, action: PayloadAction<object>) {
      Object.assign(state.data, action.payload);
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;

import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from './useAppDispatch';
import eventsApi from '../api/EventsApi';
import { getFriends } from '../api/VKWebApi';
import { EVENT_NAME, EVENT_TYPE } from '../constants/Event';
import { setFriends } from '../store/FriendSearchSlice';
import { selectFriends } from '../store/FriendSearchSlice/popoutSelectors';
import { selectLocation } from '../store/routerSlice/routerSelectors';
import { handleErrorToStore } from '../utils/handleErrorToStore';


export const useGetFriends = () => {
  const dispatch = useAppDispatch();
  const friends = useSelector(selectFriends);
  const location = useSelector(selectLocation);

  return useCallback(async (accessToken: string) => {
    if (friends) {
      return friends;
    }

    let newFriends;
    try {
      newFriends = await getFriends(accessToken);
    } catch (error) {
      await eventsApi.sendStatistics({
        type: EVENT_TYPE.VK_API,
        name: EVENT_NAME.FRIENDS_GET_VK_API_ERROR,
        data: {
          message: 'Ошибка при доступе к друзьям (useGetFriends)',
          error: handleErrorToStore(error),
        },
        route: location,
      });

      throw error;
    }

    dispatch(setFriends(newFriends));
    return newFriends;
  }, [friends, dispatch, location]);
};

import React from 'react';

import { CholericSVG as DesktopCholericSVG } from '../../../../../../images/desktop/CholericSVG';
import { MelancholicSVG as DesktopMelancholicSVG } from '../../../../../../images/desktop/MelancholicSVG';
import { PhlegmaticSVG as DesktopPhlegmaticSVG } from '../../../../../../images/desktop/PhlegmaticSVG';
import { SanguineSVG as DesktopSanguineSVG } from '../../../../../../images/desktop/SanguineSVG';
import { CholericSVG as MobileCholericSVG } from '../../../../../../images/mobile/CholericSVG';
import { MelancholicSVG as MobileMelancholicSVG } from '../../../../../../images/mobile/MelancholicSVG';
import { PhlegmaticSVG as MobilePhlegmaticSVG } from '../../../../../../images/mobile/PhlegmaticSVG';
import { SanguineSVG as MobileSanguineSVG } from '../../../../../../images/mobile/SanguineSVG';

export const getTemperamentOptions = (paragraphStyle: any = {}) => ({
  sanguine: {
    name: 'сангвиник',
    image: {
      mobile: <MobileSanguineSVG width="100%" height="auto" />,
      desktop: <DesktopSanguineSVG width="100%" height="auto" />,
    },
    storyImage: {
      me: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundSanguineMe.png',
      other: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundSanguineOther.png',
    },
    wallImage: {
      me: 'photo-180721685_457239089',
      other: 'photo-180721685_457239090',
    },
    strongFeatures: (
      <>
        <p style={paragraphStyle}>Сангвиник — это оптимистичный и общительный человек, с высоким уровнем энергии, уравновешенный и способный сохранять самообладание в сложных ситуациях. Он умело адаптируется, обладает высокой работоспособностью и выносливостью. Сангвиник постоянно в движении, так как он является ярко выраженным экстравертом, который всегда стремится к общению, а длительное одиночество вызывает у него дискомфорт.</p>
        <p style={paragraphStyle}>Люди данного типа темперамента отличаются гибкостью поведения и умением успешно адаптироваться к быстро меняющимся обстоятельствам. Они обычно обладают отличным чувством юмора, излучают позитив и способны поднимать настроение окружающим, даже в неприятных ситуациях.</p>
      </>
    ),
    weakFeatures: (
      <>
        <p style={paragraphStyle}>Постоянно переключаясь на новые занятия, они часто оставляют предыдущие дела незавершенными. Нередко они могут прекращать заниматься делом, в котором достигли значительного успеха, просто потеряв к нему интерес. Они склонны к частым сменам увлечений, быстро погружаясь в новую тему и так же быстро ее отбрасывая. Их знания в различных областях часто остаются поверхностными, так как они редко углубляются в какую-то тему настолько, чтобы освоить ее глубоко.</p>
        <p style={paragraphStyle}>Жизнь сангвиника насыщена событиями, поэтому у него обычно нет времени задумываться о чувствах других людей. Позитивная и сильная личность, он редко осознает, что кто-то другой может страдать от неудач и нуждаться в поддержке. Кроме того, с детства он привык быть в центре внимания, что может способствовать формированию эгоистического отношения к окружающим.</p>
      </>
    ),
    famousPersons: [
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/MarkTven300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/MarkTven20.png',
        name: 'Марк Твен',
        subtitle: 'Писатель'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Bonapart300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Bonapart20.png',
        name: 'Наполеон Бонапарт',
        subtitle: 'Полководец'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Messi300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Messi20.png',
        name: 'Лионель Месси',
        subtitle: 'Футболист'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Presli300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Presli20.png',
        name: 'Элвис Пресли',
        subtitle: 'Певец'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Motzart300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Motzart20.png',
        name: 'Вольфган Амадей Моцарт',
        subtitle: 'Композитор'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Chipollino300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Chipollino20.png',
        name: 'Чиполлино',
        subtitle: '«Приключения Чиполлино»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/SpongeBob300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/SpongeBob20.png',
        name: 'Губка Боб',
        subtitle: '«Губка Боб Квадратные Штаны»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Krosh300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Krosh20.png',
        name: 'Крош',
        subtitle: '«Смешарики»'
      },
    ]
  },
  choleric: {
    name: 'холерик',
    image: {
      mobile: <MobileCholericSVG width="100%" height="auto" />,
      desktop: <DesktopCholericSVG width="100%" height="auto" />,
    },
    storyImage: {
      me: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundСholericMe.png',
      other: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundСholericOther.png',
    },
    wallImage: {
      me: 'photo-180721685_457239092',
      other: 'photo-180721685_457239091',
    },
    strongFeatures: (
      <>
        <p style={paragraphStyle}>Когда холерик заинтересован в каком-либо деле, он способен работать над ним практически непрерывно, вкладывая в эту работу всю свою энергию. Он упорно движется к поставленной цели, не останавливаясь на неудачах, а гибко переключается на новые задачи, справляясь с поражениями легко и оперативно.</p>
        <p style={paragraphStyle}>Холерики убедительно выражают свои мысли, принимают решения быстро, редко испытывают сомнения и сохраняют оптимизм. Они обладают гибким умом и способностью принимать быстрые решения, их реакция на события стремительна. Многие люди восхищаются решительностью холериков и готовы идти за ними, даваясь вдохновляться их целеустремленностью.</p>
      </>
    ),
    weakFeatures: (
      <>
        <p style={paragraphStyle}>Люди с данным типом темперамента обычно проявляют вспыльчивость, нестабильность и склонность к раздражительности. Когда что-то раздражает холерика, он быстро теряет самообладание и может проявить грубость даже по отношению к близким людям. Для него такие эмоциональные реакции становятся привычными, и после конфликта он часто не испытывает угрызений совести или не признает, что его поведение нанесло серьезный ущерб отношениям.</p>
        <p style={paragraphStyle}>Холерики часто отличаются гиперактивностью, стремлением к лидерству и руководству. Однако, быстро принимая решения, они часто настаивают на их принятии окружающим. Также они предпочитают спорить и редко признают собственные ошибки, что часто приводит к конфликтам в дискуссиях.</p>
      </>
    ),
    famousPersons: [
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Pushkin300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Pushkin20.png',
        name: 'Александр Сергеевич Пушкин',
        subtitle: 'Поэт'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/BillGates300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/BillGates20.png',
        name: 'Билл Гейтс',
        subtitle: 'Предприниматель'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/SalvadorDali300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/SalvadorDali20.png',
        name: 'Сальвадор Дали',
        subtitle: 'Художник'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Beethoven300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Beethoven20.png',
        name: 'Людвиг Ван Бетховен',
        subtitle: 'Композитор'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/VinniPukh300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/VinniPukh20.png',
        name: 'Вини-Пух',
        subtitle: '«Винни-Пух»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/zebraMarti300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/zebraMarti20.png',
        name: 'зебра Марти',
        subtitle: '«Мадагаскар»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/GarryPotter300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/GarryPotter20.png',
        name: 'Гарри Поттер',
        subtitle: '«Гарри Поттер»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Mufasa300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Mufasa20.png',
        name: 'Муфаса',
        subtitle: '«Король-Лев»'
      },
    ]
  },
  melancholic: {
    name: 'меланхолик',
    image: {
      mobile: <MobileMelancholicSVG width="100%" height="auto" />,
      desktop: <DesktopMelancholicSVG width="100%" height="auto" />,
    },
    storyImage: {
      me: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundMelancholicMe.png',
      other: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundMelancholicOther.png',
    },
    wallImage: {
      me: 'photo-180721685_457239093',
      other: 'photo-180721685_457239094',
    },
    strongFeatures: (
      <>
        <p style={paragraphStyle}>Меланхолики уделяют большое внимание эмоциям — своим собственным и эмоциям окружающих. Они всегда готовы выслушать и поддержать близких, при этом сильно сопереживают чужим переживаниям. Люди с темпераментом меланхолика часто стремятся помогать тем, кто в нужде, будучи готовыми оказывать бескорыстную помощь незнакомцам, участвуя в благотворительных мероприятиях, а также заботясь о бездомных животных.</p>
        <p style={paragraphStyle}>Меланхолики обычно способны за несколько часов общения составить полный психологический портрет собеседника. Они отлично разбираются в людях, замечают и точно определяют чужие эмоции. Меланхолик сразу же распознает, если вы пытаетесь представить себя другим, чем на самом деле являетесь. Эти навыки психолога полезно применять в бизнесе и в личных отношениях.</p>
      </>
    ),
    weakFeatures: (
      <>
        <p style={paragraphStyle}>Если говорить о типичном меланхолике, важно отметить, что их основная черта — это чувствительность. Они имеют склонность к грусти по любому поводу. Они могут быть задеты даже неосознанной шуткой, которую воспримут слишком серьезно. Ранив такого человека можно любой фразой или поступком, который не соответствует их ожиданиям.</p>
        <p style={paragraphStyle}>Меланхолик всегда стремится к идеалу и очень расстраивается, осознав, что идеал недостижим. Он критично относится к качеству работы, поэтому часто завышает требования к себе и критикует окружающих.</p>
      </>
    ),
    famousPersons: [
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Esenin300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Esenin20.png',
        name: 'Сергей Есенин',
        subtitle: 'Поэт'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Newton300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Newton20.png',
        name: 'Исаак Ньютон',
        subtitle: 'Ученый'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Darwin300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Darwin20.png',
        name: 'Чарльз Дарвин',
        subtitle: 'Ученый'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Jekson300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Jekson20.png',
        name: 'Майкл Джексон',
        subtitle: 'Певец'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Piero300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Piero20.png',
        name: 'Пьеро',
        subtitle: '«Золотой ключик, или Приключения Буратино»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Cheburashka300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Cheburashka20.png',
        name: 'Чебурашка',
        subtitle: '«Чебурашка»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Frodo300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Frodo20.png',
        name: 'Фродо',
        subtitle: '«Властелин Колец»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Shifu300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Shifu20.png',
        name: 'мастер Шифу',
        subtitle: '«Кунг-фу Панда»'
      }
    ]
  },
  phlegmatic: {
    name: 'флегматик',
    image: {
      mobile: <MobilePhlegmaticSVG width="100%" height="auto" />,
      desktop: <DesktopPhlegmaticSVG width="100%" height="auto" />,
    },
    storyImage: {
      me: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundPhlegmaticMe.png',
      other: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/StoryBackgroundPhlegmaticOther.png',
    },
    wallImage: {
      me: 'photo-180721685_457239095',
      other: 'photo-180721685_457239088',
    },
    strongFeatures: (
      <>
        <p style={paragraphStyle}>Флегматик сохраняет свои убеждения и редко меняет свои высказывания. Однако, если становится очевидным, что он ошибся, он готов признать свою ошибку. Его постоянство в отношениях делает его надежным партнером в бизнесе, верным другом или преданным спутником в жизни.</p>
        <p style={paragraphStyle}>Флегматики обладают способностью реагировать на стресс, сохраняя спокойствие. Вместо эмоциональных всплесков они склонны отвечать на сложные стрессовые ситуации философскими замечаниями. Они не избегают реальности, а предпочитают разумно действовать, не допуская эмоций управлять собой. При этом они внимательно относятся к качеству своей работы, уделяют внимание каждой детали, тщательно проверяют все, чтобы исключить ошибки.</p>
      </>
    ),
    weakFeatures: (
      <>
        <p style={paragraphStyle}>Люди с этим типом темперамента не стремятся к радикальным изменениям. Они предпочитают, чтобы ситуация развивалась согласно заранее продуманному плану. Кроме того, у них отсутствуют амбиции, и они предпочитают игнорировать некоторые проблемы. Их собственная пассивность устраивает флегматиков, они не видят смысла в стремлении к развитию, часто упуская возможность улучшить свою жизнь.</p>
        <p style={paragraphStyle}>Флегматики с осторожностью впускают новых людей в свою жизнь. Вам понадобится немало усилий, чтобы установить близкие отношения с человеком такого типа темперамента. Даже если вы станете для него близким другом, помните, что у флегматика будут частые желания проводить время в одиночестве.</p>
      </>
    ),
    famousPersons: [
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Galileo300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Galileo20.png',
        name: 'Галилео Галилей',
        subtitle: 'Ученый'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Kutuzov300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Kutuzov20.png',
        name: 'Михаил Кутузов',
        subtitle: 'Полководец'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/KeanuReeves300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/KeanuReeves20.png',
        name: 'Киану Ривз',
        subtitle: 'Актер'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Krylov300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Krylov20.png',

        name: 'Иван Крылов',
        subtitle: 'Баснописец'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Leopold300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Leopold20.png',
        name: 'кот Леопольд',
        subtitle: '«Приключения кота Леопольда»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Ezhik300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Ezhik20.png',
        name: 'Ёжик',
        subtitle: '«Смешарики»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Kaa300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Kaa20.png',
        name: 'Каа',
        subtitle: '«Маугли»'
      },
      {
        image: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Hagrid300.png',
        smallImage: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychopredict-static/Hagrid20.png',

        name: 'Рубеус Хагрид',
        subtitle: '«Гарри Поттер»'
      },
    ]
  },
});

export const storyTemplate = {
  story_box: {
    background_type: 'image',
    url: '',  // Место для картинки в сторис
    attachment: {
      text: 'open',
      type: 'url',
      url: 'https://vk.com/vk_temperament'
    },
  },
  successfulMyEvent: { type: 'story',  name: 'successfully_uploaded_my_results_in_story' },
  successfulOtherEvent: { type: 'story',  name: 'successfully_uploaded_other_results_in_story' },
  failedMyEvent: { type: 'story', name: 'failed_to_upload_my_results_in_story' },
  failedOtherEvent: { type: 'story', name: 'failed_to_upload_other_results_in_story' },
};

export const USER_DENIED_ERROR_MESSAGE = 'User denied';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { friendSearchInitialState } from './constants';
import { SelectedUserDataType } from './types';

const friendSearchSlice = createSlice({
  name: 'friendSearch',
  initialState: friendSearchInitialState,
  reducers: {
    setIsSelectedUserPurchased(state, action: PayloadAction<boolean | undefined>) {
      state.isSelectedUserPurchased = action.payload;
    },
    setSelectedUserData(state, action: PayloadAction<SelectedUserDataType | undefined>) {
      state.selectedUserData = action.payload;
    },
    clearSelectedUser(state) {
      state.selectedUserData = friendSearchInitialState.selectedUserData;
      state.isSelectedUserPurchased = friendSearchInitialState.isSelectedUserPurchased;
    },
    setFriends(state, action: PayloadAction<SelectedUserDataType[] | undefined>) {
      state.friends = action.payload;
    },
    updateFriendSearchData(state, action: PayloadAction<{ sex: 'male' | 'female' | undefined }>) {
      Object.assign(state, action.payload);
    },
    clearFriendSearchData(state) {
      state.sex = friendSearchInitialState.sex;
    },
  },
});

export const {
  updateFriendSearchData,
  clearFriendSearchData,
  setSelectedUserData,
  clearSelectedUser,
  setFriends,
  setIsSelectedUserPurchased,
} = friendSearchSlice.actions;

export default friendSearchSlice.reducer;

import React from 'react';

import { Text } from '@vkontakte/vkui';

import styles from './IconedText.module.css';
import { ResultPanelPropsType } from './types';

export const IconedText: React.FC<ResultPanelPropsType> = ({
  icon,
  text,
  textStyle,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        {icon}
      </div>
      <Text className={styles.text} style={textStyle}>
        {text}
      </Text>
    </div>
  );
};

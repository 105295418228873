import React from 'react';

import { useSelector } from 'react-redux';

import { PredictionPanel } from './PredictionPanel';
import { PredictionPanelContainerProps } from './types';
import { selectIsDesktop } from '../../../../store/settingsSlice/settingsSelectors';
import { cacheImages } from '../../../../utils/cacheImages';
import { getTemperamentOptions } from '../ResultPanel/components/ResultContent/constants';

const PredictionPanelContainer: React.FC<PredictionPanelContainerProps> = ({ id }) => {
  const isDesktop = useSelector(selectIsDesktop);
  const temperamentOptions = getTemperamentOptions();
  const imagesToCash = Object.values(temperamentOptions).map(
    ({ famousPersons }: any) => famousPersons.map(({ image }: any) => image)
  ).flat(1);
  cacheImages(imagesToCash);

  return (
    <PredictionPanel id={id} isDesktop={isDesktop} />
  );
};

export default PredictionPanelContainer;

import React from 'react';
import { useEffect, useCallback, useState, ReactNode } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from './useAppDispatch';
import popoutMap from '../components/popouts';
import { POPOUT, PopoutType } from '../constants/Popout';
import { setPopoutType, resetPopoutType, setPopout } from '../store/popoutSlice';
import { selectPopoutType } from '../store/popoutSlice/popoutSelectors';

export const usePopout = () => {
  const dispatch = useAppDispatch();

  const [popout, setPopout] = useState<ReactNode>(null);
  const popoutType = useSelector(selectPopoutType);

  useEffect(() => {
    if (!popoutType) {
      setPopout(null);
      return;
    }

    const PopoutComponent = React.createElement(popoutMap[popoutType]);
    setPopout(PopoutComponent);
  }, [popoutType]);

  const set = useCallback((type: PopoutType) => dispatch(setPopoutType(type)), [dispatch]);
  const reset = useCallback(() => dispatch(resetPopoutType()), [dispatch]);

  return {
    popout,
    setPopout: set,
    resetPopout: reset,
  };
};

export const useSetPopoutAlert = () => {
  const dispatch = useAppDispatch();

  return useCallback( (
    title: string,
    description: ReactNode,
    onClick: () => void = () => {},
    buttonText = 'Назад'
  ) => {
    dispatch(setPopout({
      type: POPOUT.POPOUT_ALERT,
      meta_info: {
        title,
        description,
        onClick: async () => {
          onClick();
          dispatch(resetPopoutType());
        },
        buttonText
      }
    }));
  }, [dispatch]);
};
